import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { Button, Grid, TextField } from '@material-ui/core';
import moment from 'moment';

let ViewWholesalePOCredits = ({ wholesaleAccount }) => {
    let [rows, setRows] = useState([]);
    let [reportIsReady, setReportIsReady] = useState(false);
    let [reportButtonText, setReportButtonText] = useState('Loading...');
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);
    const [notification, setNotification] = useState({ text: '', level: '' });

    const [reportStartDate, setReportStartDate] = React.useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));
    let handleReportStartDateChange = (event) => {
        setReportStartDate(event.target.value);
    };

    const [reportEndDate, setReportEndDate] = React.useState(moment().format('YYYY-MM-DD'));
    let handleReportEndDateChange = (event) => {
        setReportEndDate(event.target.value);
    };

    let handleRunReportClick = (event) => {
        getAndRenderReportData();
    };

    let getAndRenderReportData = async () => {
        setReportIsReady(false);

        setReportButtonText('Loading...');
        setRows([]);

        let response = await api.getPOCredits(reportStartDate, reportEndDate);

        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setReportButtonText('Run Report');
            setReportIsReady(true);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.issuedOn = moment(row.issuedOn.date).format('MM/DD/YYYY HH:mm:ss');
                row.deliveryDate = moment(row.deliveryDate.date).format('MM/DD/YYYY');
                row.dollarsCredited = parseFloat((row.price * row.numCredited).toFixed(2));
                return row;
            });
        }

        setRows(response.data.rows);
        setReportButtonText('Run Report');
        setReportIsReady(true);
    };

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    useEffect(() => {
        getAndRenderReportData();
    }, [api]);

    let cols = [
        { accessor: 'poNum', Header: 'PO#' },
        { accessor: 'microFC', Header: 'MFC' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'itemName', Header: 'Product Name' },
        { accessor: 'numOrdered', Header: '# Ordered' },
        { accessor: 'price', Header: 'Price' },
        { accessor: 'numCredited', Header: '# Credited' },
        { accessor: 'dollarsCredited', Header: '$ Credited' },
        { accessor: 'reason', Header: 'Reason' },
        { accessor: 'issuedOn', Header: 'Issued On' },
        { accessor: 'issuedBy', Header: 'Issued By' },
    ];

    return (
        <div>
            <h1>{wholesaleAccount.companyName} PO Credits</h1>
            <Notifications options={notification} />
            <Grid item md={12}>
                <TextField
                    id="reportStartDate"
                    label="Start Date"
                    type="date"
                    value={reportStartDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportStartDateChange}
                    style={{ marginRight: '50px' }}
                />

                <TextField
                    id="reportEndDate"
                    label="End Date"
                    type="date"
                    value={reportEndDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={!reportIsReady}
                    onChange={handleReportEndDateChange}
                    style={{ marginRight: '50px' }}
                />

                <Button id="runReport" onClick={handleRunReportClick} color="secondary" disabled={!reportIsReady}>
                    {reportButtonText}
                </Button>
            </Grid>
            <DataTable columns={cols} data={rows} />
        </div>
    );
};

export { ViewWholesalePOCredits };
