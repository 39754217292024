import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { UserContext } from '../../../contexts/UserContext';

let WholesaleAdjustmentUpload = () => {
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    const user = useContext(UserContext);

    let columns = [
        { Header: 'Delivery Date', accessor: 'deliveryDate' },
        { Header: 'Market', accessor: 'market' },
        { Header: 'Micro FC', accessor: 'microFC' },
        { Header: 'PO Num', accessor: 'poNum' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: wholesaleAccount.companyName+' Sku', accessor: wholesaleAccount.fbmAccountName+'Sku' },
        { Header: 'Product Name', accessor: 'productName' },
        { Header: 'Qty', accessor: 'qty' },
        { Header: 'Allocated Qty', accessor: 'allocatedQty' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmAllocationUpload(key);
        return res;
    };

    let uploadAllocation = async (formData) => {
        let res = await api.uploadAllocation(formData, user.getFacilityID());
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Delivery Date,Market,MicroFC,PO Num,';
        csvContent += `External SKU,${wholesaleAccount.companyName} SKU,Product Name,Qty,Allocated Qty`;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', wholesaleAccount.fbmAccountName+'_allocation_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <Link to={'/gopuff/batches'}>Batchelor</Link>
            <UploadCSVAndReviewComponent
                title={`Upload ${wholesaleAccount.companyName} Allocation`}
                fileLabel="Allocation File"
                columns={columns}
                upload={uploadAllocation}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { WholesaleAdjustmentUpload };
