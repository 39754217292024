import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, WholesaleAccountPicker } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { UserContext } from '../../../contexts/UserContext';

let ViewGoPuffPricing = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);


    // Load  table
    let getRows = async () => {
        let response = await api.getPricing();
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        if (response.data.rows) {
            response.data.rows.map((row) => {
                row.createdAt = moment(row.createdAt.date).format('MM/DD/YYYY');
                row.effectiveDate = moment(row.effectiveDate.date).format('MM/DD/YYYY');
                return row;
            });
        }

        setRows(response.data.rows);
    };

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    useEffect(() => {
        if (!!wholesaleAccount.id) {
            getRows();
        }
    }, [api]);

    let columns = [
        { Header: 'Pricing ID', accessor: 'id' },
        { Header: 'External SKU', accessor: 'externalSku' },
        { Header: 'Effective Date', accessor: 'effectiveDate' },
        { Header: 'Each Cost', accessor: 'eachCost' },
        { Header: 'Case Cost', accessor: 'caseCost' },
        { Header: 'Currency', accessor: 'currency' },
        { Header: 'Facility', accessor: 'facilityID' },
    ];

    return (
        <div>
            <WholesaleAccountPicker />
            {!!wholesaleAccount.id && (
                <>
                    <h1>{wholesaleAccount.companyName} Pricing</h1>
                    <Link to={'/gopuff/pricing-upload'}>Upload</Link>
                    <Notifications options={notification} />
                    {}
                    <div>
                        <DataTable columns={columns} data={rows} />
                    </div>
                </>
            )}
        </div>
    );
};

export { ViewGoPuffPricing };
