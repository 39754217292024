import React, { useState, useEffect, useContext } from 'react';
import { FormComponent, Notifications, DataTable } from '../../../shared';
import { Button, Dialog, DialogContent } from '@material-ui/core';
import { addressesApi } from '../../../api/addresses';
import { procurementApi as api } from '../../../api/procurement';
import { warehouseApi } from '../../../api/warehouse';
import { UserContext } from '../../../contexts/UserContext';
import { logisticsApi } from '../../../api/logistics';
import { catalogApi } from '../../../api/catalog';
import { dateHelper } from '../../../shared/utils/dateHelper';
import moment from 'moment';
import PurchaseOrderGuardrail from './purchaseOrderGuardrail';

const PO_ALERT_VALUE = 50_000;
const PO_WARNING_VALUE = 200_000;

let CreatePO = ({ initialValues = {}, onSuccess }) => {
    let [vendors, setVendors] = useState([]);
    let [shippers, setShippers] = useState([]);
    let [buyers, setBuyers] = useState([]);
    let [countries, setCountries] = useState([]);
    let [facilities, setFacilities] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [deliveryTypes, setDeliveryTypes] = useState([]);
    let [crossDocks, setCrossDocks] = useState([]);
    let [deliveryType, setDeliveryType] = useState(initialValues.deliveryType || '');
    let [pickUpDate, setPickUpDate] = useState('');

    let [originalRequestedDeliveryDate, setOriginalRequestedDeliveryDate] = useState('');

    let [isReceivedAtCrossDock, setIsReceivedAtCrossDock] = useState(initialValues.isReceivedAtCrossDock || false);

    // Handle changes to the delivery type
    const handleDeliveryTypeChange = (event) => {
        setDeliveryType(event.target.value);
    };

    const handleIsReceivedAtCrossDockChange = (event) => {
        setIsReceivedAtCrossDock(event.target.value === 'YES');
    };

    // Handle changes to the pick up date
    const handlePickUpDateChange = (event) => {
        setPickUpDate(event.target.value);
    };

    const [schedDeliveryDate, setSchedDeliveryDate] = useState();
    const [prevSchedDelDate, setPrevSchedDelDate] = useState();
    const [poGuardrailModalOpen, setPoGuardrailModalOpen] = useState(false);

    const [formData, setFormData] = useState({});
    const [resetForm, setResetForm] = useState();

    const user = useContext(UserContext);

    const [poTotal, setPoTotal] = useState(0);
    const [dtcIssueCount, setDtcIssueCount] = useState(0);
    const [issueModalOpen, setIssueModalOpen] = useState(false);
    const [guardrailMessage, setGuardrailMessage] = useState('');

    // Master  form submit function
    let createPurchaseOrder = async (formData, resetForm) => {
        if (formData.itemsInPO.length === 0) {
            setNotification({ text: 'Must provide at least 1 item', level: 'error' });
            return;
        }

        for (let item of formData.itemsInPO) {
            if (!item.qty) {
                setNotification({ text: 'Please fill in item quantity for SKU ' + item.sku, level: 'error' });
                return;
            }

            if (!item.week) {
                setNotification({ text: 'Please fill in item week number for SKU ' + item.sku, level: 'error' });
                return;
            }

            if (item.hasExpectedExpirationDate && !item.expectedExpirationDate) {
                setNotification({
                    text: 'Please fill in expected expiration date for SKU ' + item.sku,
                    level: 'error',
                });
                return;
            }

            if (item.hasExpectedProductionDate && !item.expectedProductionDate) {
                setNotification({
                    text: 'Please fill in expected production date for SKU ' + item.sku,
                    level: 'error',
                });
                return;
            }
        }

        if (formData.deliveryType === '' || formData.deliveryType === '0') {
            setNotification({ text: 'Must provide delivery type', level: 'error' });
            return;
        }

        let sendParams = {
            vendorID: formData.vendor,
            deliveryType: formData.deliveryType,
            pickUpDate: formData.pickUpDate,
            originalRequestedDeliveryDate: formData.originalRequestedDeliveryDate,
            deliverDate: formData.scheduledDeliveryDate,
            deliverTime: formData.scheduledTime,
            shippingRef: formData.shipperRef,
            crossDockID: formData.crossDock,
            crossDockDeliveryDate: formData.crossDockDeliveryDate,
            specialInstructions: formData.specialInstr,
            inboundShipperID: formData.shipper,
            facilityIDs: formData.facilities.map((item) => item.value),
            items: formData.itemsInPO,
            buyers: formData.buyers.map((item) => item.value),
            minTempReq: formData.minTempReq,
            maxTempReq: formData.maxTempReq,
            pickupNumber: formData.pickupNumber,
            priority: formData.priority === 'YES' ? true : false,
            estimatedFreightCost: formData.deliveryType === 'DELIVERED' ? formData.estimatedFreightCost : null,
            isReceivedAtCrossDock: formData.isReceivedAtCrossDock === 'YES',
        };

        let response = await api.createPurchaseOrder(sendParams);
        if (response.status === false) {
            let message = 'Error creating purchase order(s)';
            if (response) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            setNotification({ text: 'Purchase Order(s) created!', level: 'success' });
            resetForm();

            if (onSuccess && onSuccess instanceof Function) {
                onSuccess();
            }
        }
    };

    useEffect(() => {
        getVendors();
        getShippers();
        getDeliveryTypes();
        getBuyers();
        getFacilities();
        getCountries();
        getCrossDocks();
    }, []);

    let getCrossDocks = async () => {
        let response = await logisticsApi.getInboundCrossDocks(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get cross docks', level: 'error' });
            setCrossDocks([]);
            return;
        }

        let crossDocksAsOpts = response.data.rows.map((row) => {
            row.text = row?.name;
            row.value = row?.id;
            return row;
        });

        let pleaseSelect = { text: 'Please Select', value: '' };
        setCrossDocks([pleaseSelect].concat(crossDocksAsOpts));
    };

    // Load vendors
    let getVendors = async () => {
        let response = await api.getVendors();
        if (response.status === false) {
            setNotification({ text: 'No vendors found ' + response.msg, level: 'error' });
            setVendors([]);
            return;
        }

        let vendorAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setVendors(vendorAsOpts);
    };

    let getBuyers = async () => {
        let response = await api.getBuyers();
        if (response.status === false) {
            setNotification({ text: 'Failed to get buyers: ' + response.msg, level: 'error' });
            setBuyers([]);
            return;
        }

        let buyerOpts = response.data.rows.map((row) => {
            return { text: row.firstName + ' ' + row.lastName, value: row.id };
        });
        setBuyers(buyerOpts);
    };

    // Load shippers
    let getShippers = async () => {
        let response = await api.getShippers();
        if (response.status === false) {
            setNotification({ text: 'No shippers found ' + response.msg, level: 'error' });
            setShippers([]);
            return;
        }

        let shippersAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            return row;
        });

        setShippers(shippersAsOpts);
    };

    // Handle delivery days
    let getDeliveryTypes = () => {
        let deliveryOpts = [
            { text: 'Pick Up', value: 'PICK UP' },
            { text: 'Delivered', value: 'DELIVERED' },
        ];

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setDeliveryTypes([pleaseSelect].concat(deliveryOpts));
    };

    let getFacilities = async () => {
        let response = await warehouseApi.getFacilities(true);
        if (!response.status) {
            setNotification({ text: 'Failed to get facilities', level: 'error' });
            return;
        }

        let facilitiesAsOpts = response.data.rows.map((row) => {
            row.text = row.name;
            row.value = row.id;
            row.cluster = row.cluster;
            return row;
        });

        setFacilities(facilitiesAsOpts);
    };

    // Load countries
    let getCountries = async () => {
        let response = await addressesApi.getCountries();
        if (!response.status) {
            setNotification({ text: 'Failed to get countries', level: 'error' });
            return;
        }

        let countriesAsOpts = response.data.rows.map((row) => {
            row.text = `${row.name} (${row.countryCode})`;
            row.value = row.id;
            return row;
        });

        setCountries(countriesAsOpts);
    };

    let handleOriginalRequestedDeliveryDateChange = (e) => {
        if (!!originalRequestedDeliveryDate) {
            setOriginalRequestedDeliveryDate(originalRequestedDeliveryDate);
        }
        setOriginalRequestedDeliveryDate(e.target.value);
    };

    let handleSchedDelDateChange = (e) => {
        if (!!schedDeliveryDate) {
            setPrevSchedDelDate(schedDeliveryDate);
        }
        setSchedDeliveryDate(e.target.value);
    };

    let initialPriorityValue;
    if (initialValues.hasOwnProperty('priority')) {
        if (initialValues.priority) {
            initialPriorityValue = 'YES';
        } else {
            initialPriorityValue = 'NO';
        }
    }

    let initialIsReceivedAtCrossDockValue;
    if (initialValues.hasOwnProperty('isReceivedAtCrossDock')) {
        initialIsReceivedAtCrossDockValue = initialValues.isReceivedAtCrossDock ? 'YES' : 'NO';
    } else {
        initialIsReceivedAtCrossDockValue = 'NO';
    }

    // Define the form
    let formFields = [
        {
            name: 'vendor',
            inputElement: 'autocomplete',
            label: 'Vendor',
            gridValue: 3,
            initialValue: initialValues.vendorID
                ? { text: initialValues.vendorName, value: initialValues.vendorID }
                : undefined,
            inputProps: { required: true, opts: vendors },
        },
        {
            name: 'deliveryType',
            inputElement: 'select',
            label: 'Delivery Type',
            gridValue: 3,
            initialValue: deliveryType,
            inputProps: { required: true, opts: deliveryTypes, onChange: handleDeliveryTypeChange },
        },
        {
            name: 'isReceivedAtCrossDock',
            inputElement: 'select',
            label: 'Received At Cross Dock',
            gridValue: 2,
            initialValue: initialIsReceivedAtCrossDockValue,
            inputProps: { required: true, opts: ['YES', 'NO'], onChange: handleIsReceivedAtCrossDockChange },
        },
        {
            name: 'pickUpDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Pick Up Date',
            gridValue: 2,
            initialValue: pickUpDate,
            inputProps: { required: deliveryType === 'PICK UP', onChange: handlePickUpDateChange },
        },
        {
            name: 'originalRequestedDeliveryDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Original Requested Delivery Date',
            gridValue: 2,
            inputProps: { required: true, onChange: handleOriginalRequestedDeliveryDateChange },
        },
        {
            name: 'scheduledDeliveryDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Scheduled Delivery Date',
            gridValue: 2,
            inputProps: { required: true, onChange: handleSchedDelDateChange },
        },
        {
            name: 'scheduledTime',
            inputElement: 'time',
            label: 'Scheduled delivery time',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'minTempReq',
            inputElement: 'textField',
            label: 'Min Temp',
            gridValue: 1,
            initialValue: initialValues.minTempReq || undefined,
            inputProps: { type: 'number', integer: true },
        },
        {
            name: 'maxTempReq',
            inputElement: 'textField',
            label: 'Max Temp',
            gridValue: 1,
            initialValue: initialValues.maxTempReq || undefined,
            inputProps: { type: 'number', integer: true },
        },
        {
            name: 'shipper',
            inputElement: 'autocomplete',
            label: 'Shipper',
            gridValue: 3,
            initialValue: initialValues.shipperID
                ? { text: initialValues.shipperName, value: initialValues.shipperID }
                : undefined,
            inputProps: { opts: shippers },
        },
        {
            name: 'crossDock',
            inputElement: 'select',
            label: 'Cross Dock',
            gridValue: 3,
            initialValue: initialValues.crossDockID
                ? { text: initialValues.crossDockName, value: initialValues.crossDockID }
                : undefined,
            inputProps: { opts: crossDocks, required: isReceivedAtCrossDock },
        },
        {
            name: 'crossDockDeliveryDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Cross Dock Delivery Date',
            gridValue: 2,
        },
        {
            name: 'facilities',
            inputElement: 'autocomplete',
            label: 'Facilities',
            gridValue: 2,
            initialValue: initialValues.facilitiesWithNames
                ? initialValues.facilitiesWithNames.map((item) => ({ text: item.name, value: item.facilityID }))
                : undefined,
            inputProps: { required: true, multiple: true, opts: facilities },
        },
        {
            name: 'buyers',
            inputElement: 'autocomplete',
            label: 'Buyers',
            gridValue: 2,
            initialValue: initialValues.buyersWithNames
                ? initialValues.buyersWithNames.map((item) => ({ text: item.name, value: item.authUserID }))
                : undefined,
            inputProps: { required: true, multiple: true, opts: buyers },
        },
        {
            name: 'pickupNumber',
            inputElement: 'textField',
            label: 'Pick Up #',
            gridValue: 2,
        },
        { name: 'specialInstr', inputElement: 'textField', label: 'Special Instructions', gridValue: 8 },
        {
            name: 'priority',
            inputElement: 'select',
            label: 'Priority',
            gridValue: 2,
            initialValue: initialPriorityValue,
            inputProps: { required: true, opts: ['YES', 'NO'] },
        },
        {
            name: 'estimatedFreightCost',
            inputElement: 'textField',
            label: 'Freight Cost',
            display: (formData) => formData.deliveryType === 'DELIVERED',
            gridValue: 2,
            initialValue: initialValues.estimatedFreightCost || undefined,
            inputProps: { type: 'number' },
        },
        {
            name: 'itemsInPO',
            label: 'Items',
            inputElement: ({ formData, setFormField }) => (
                <AddItemMasters
                    countries={countries}
                    setNotification={setNotification}
                    initialValue={initialValues}
                    formData={formData}
                    setFormField={setFormField}
                    vendor={formData.vendor}
                    schedDeliveryDate={schedDeliveryDate}
                    originalRequestedDeliveryDate={originalRequestedDeliveryDate}
                    prevSchedDelDate={prevSchedDelDate}
                    setPoTotal={setPoTotal}
                    setDtcIssueCount={setDtcIssueCount}
                />
            ),
        },
    ];

    const submitPO = (formData, resetForm) => {
        createPurchaseOrder(formData, resetForm);
        setDtcIssueCount(0);
    };

    const handleWarningSubmit = (formData, resetForm) => {
        setFormData(formData);
        setResetForm(() => resetForm);
        setPoGuardrailModalOpen(true);
    };

    return (
        <div>
            {issueModalOpen && (
                <IssuesDetectedModal
                    issueModalOpen={issueModalOpen}
                    setIssueModalOpen={setIssueModalOpen}
                    dtcIssueCount={dtcIssueCount}
                />
            )}
            {poGuardrailModalOpen && (
                <PurchaseOrderGuardrail
                    open={poGuardrailModalOpen}
                    setOpen={setPoGuardrailModalOpen}
                    onSubmit={() => submitPO(formData, resetForm)}
                    message={guardrailMessage}
                />
            )}
            <h1>Create Purchase Order</h1>
            <Notifications options={notification} />
            <div style={{ outline: 0, backgroundColor: 'white' }}>
                <FormComponent
                    formFields={formFields}
                    onSubmit={async (formData, resetForm) => {
                        let fn = () => resetForm();
                        if (poTotal >= PO_WARNING_VALUE) {
                            setGuardrailMessage(
                                `The total for this PO is greater than $200k.
                                You will not be able to send the PO to the vendor unless you are a buyer department lead.
                                Do you wish to proceed?`
                            );
                            handleWarningSubmit(formData, fn);
                        } else if (poTotal >= PO_ALERT_VALUE) {
                            setGuardrailMessage(`The total for this PO is greater than $50k. Do you wish to proceed?`);
                            handleWarningSubmit(formData, fn);
                        } else {
                            submitPO(formData, resetForm);
                        }
                    }}
                />
            </div>
        </div>
    );
};

let IssuesDetectedModal = ({ dtcIssueCount, issueModalOpen, setIssueModalOpen }) => {
    return (
        <Dialog
            open={issueModalOpen}
            onClose={() => {
                setIssueModalOpen(false);
            }}
            fullWidth={true}
            maxWidth="sm"
            onClick={(e) => e.stopPropagation()}
        >
            <DialogContent>
                <h3>DTC Issues</h3>
                {dtcIssueCount > 0 && (
                    <>
                        <p>Discount to Customer Issues:</p>
                        <ul>
                            <li>
                                The <strong>SRP</strong> and/or <strong>MSRP</strong> values are causing the DTC of one
                                or more items to go below 10%
                            </li>
                        </ul>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

let AddItemMasters = ({
    countries,
    setNotification,
    setFormField,
    formData,
    initialValue,
    vendor,
    schedDeliveryDate,
    originalRequestedDeliveryDate,
    prevSchedDelDate,
    setPoTotal,
    setDtcIssueCount,
}) => {
    let [itemMasterOptions, setItemMasterOptions] = useState([]);
    let [externalSkuOptions, setExternalSkuOptions] = useState([]);

    let [itemMasterFullOptions, setItemMasterFullOptions] = useState([]);

    let [disableExternalSkuAutoComplete, setDisableExternalSkuAutoComplete] = useState(false);

    let [itemMaster, setItemMaster] = useState([]);
    let [inactiveSku, setInactiveSku] = useState(false);
    let [hasPrintedExpDate, setHasPrintedExpDate] = useState(false);
    let [hasPrintedProdDate, setHasPrintedProdDate] = useState(false);
    let [purchaseOpts, setPurchaseOpts] = useState([]);
    let [editPurchaseOpts, setEditPurchaseOpts] = useState([]);
    let [sourcingReasons, setSourcingReasons] = useState([]);
    let [unpurchasedOutcomes, setUnpurchasedOutcomes] = useState([]);

    const [expProdDate, setExpProdDate] = useState();
    const [expExpireDate, setExpExpireDate] = useState();
    const [currentItem, setCurrentItem] = useState();
    const [purchaseType, setPurchaseType] = useState();
    const [weekNumber, setWeekNumber] = useState();

    let schedDate = moment(schedDeliveryDate);
    let prevSchedDate = moment(prevSchedDelDate);

    let initValue = [];
    if (initialValue && initialValue.additionalData) {
        initValue = initialValue.additionalData.map((item) => {
            item.week = '';
            item.expectedExpirationDate = '';
            item.itemMasterName = item.itemName;
            return item;
        });
    }
    let [itemsInPO, setItemsInPO] = useState(initValue);

    let clearItems = () => {
        setItemsInPO([]);
    };

    let getItemTypes = async () => {
        let res = await api.getPurchaseOrderItemTypes();
        if (res.status === false) {
            return;
        }

        let pleaseSelect = { text: 'Please Select', value: 0 };
        setEditPurchaseOpts([pleaseSelect].concat(res.data.types));
        setPurchaseOpts(res.data.types);
    };

    useEffect(() => {
        const fetchSourcingReasons = async () => {
            const resp = await api.getSourcingReasons();
            if (resp.status) {
                setSourcingReasons(resp.data.reasons.map((r) => r.name));
            }
        };
        fetchSourcingReasons().catch(console.error);
    }, []);

    useEffect(() => {
        const fetchUnpurchasedOutcomes = async () => {
            const resp = await api.getUnpurchasedOutcomes();
            if (resp.status) {
                setUnpurchasedOutcomes(resp.data.outcomes.map((o) => o.displayName));
            }
        };
        fetchUnpurchasedOutcomes().catch(console.error);
    }, []);

    useEffect(() => {
        // reset form (after submit) clears formData.itemsInPO, but does not reset internal itemsInPO
        // we need to do this manually when the form data has been cleared and there is no initial value
        if (itemsInPO.length && !formData.itemsInPO && !initValue.length) {
            clearItems();
        }
    }, [formData]);

    useEffect(() => {
        let weekNum = dateHelper.getWeekNumber(originalRequestedDeliveryDate, 'YYYY-MM-DD');
        setWeekNumber(weekNum)
        let items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
            item.week = weekNum;
            return item;
        });

        setItemsInPO(items);
    }, [originalRequestedDeliveryDate]);

    useEffect(() => {
        let daysBetweenSchedDates;

        if (schedDate.isAfter(prevSchedDate)) {
            daysBetweenSchedDates = schedDate.diff(prevSchedDate, 'days');
            let hours = schedDate.diff(prevSchedDate, 'hours');
            if (daysBetweenSchedDates === 0) {
                daysBetweenSchedDates = 1;
            } else if (hours % 24) {
                daysBetweenSchedDates += 1;
            }
        } else {
            daysBetweenSchedDates = -1 * prevSchedDate.diff(schedDate, 'days');
        }

        //update expected expiration date for items in temp table before PO submission
        let items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
            const isPrePlannedItem =
                item.purchaseType === 'EVERYDAY' ||
                item.purchaseType === 'PRIVATE_BRAND' ||
                item.purchaseType === 'SEASONAL';
            if (item.expectedExpirationDate && isPrePlannedItem) {
                item.expectedExpirationDate = moment(item.expectedExpirationDate)
                    .add(daysBetweenSchedDates, 'days')
                    .format('YYYY-MM-DD');
            } else {
                initValue.map((initItem) => {
                    if (item.sku === initItem.sku && item.vendorGuaranteedDaysUponDelivery > 0 && isPrePlannedItem) {
                        let tempSchedDate = moment(schedDate);
                        item.expectedExpirationDate = tempSchedDate
                            .add(item.vendorGuaranteedDaysUponDelivery, 'days')
                            .format('YYYY-MM-DD');
                    }
                });
            }
            return item;
        });

        setItemsInPO(items);

        if (currentItem && hasPrintedProdDate) {
            setExpProdDate(
                schedDate
                    .add(currentItem.vendorGuaranteedDaysUponDelivery, 'days')
                    .subtract(currentItem.expireLifeDays, 'days')
                    .format('YYYY-MM-DD')
            );
        }
        if (currentItem && hasPrintedExpDate && currentItem.vendorGuaranteedDaysUponDelivery > 0) {
            setExpExpireDate(schedDate.add(currentItem.vendorGuaranteedDaysUponDelivery, 'days').format('YYYY-MM-DD'));
        }
    }, [schedDeliveryDate]);

    useEffect(() => {
        if (
            hasPrintedExpDate &&
            (purchaseType === 'EVERYDAY' || purchaseType === 'PRIVATE_BRAND' || purchaseType === 'SEASONAL') &&
            currentItem.vendorGuaranteedDaysUponDelivery > 0
        ) {
            setExpExpireDate(schedDate.add(currentItem.vendorGuaranteedDaysUponDelivery, 'days').format('YYYY-MM-DD'));
        } else {
            setExpExpireDate();
        }
    }, [purchaseType]);

    let getExternalSku = async () => {
        let result = await catalogApi.getItemInformation(true, 'itemMaster');

        if (result.status === false) return;

        setExternalSkuOptions(
            result.data.items.map((item) => {
                const displayName = item.name ? item.name : 'NO NAME PROVIDED';
                return {
                    text: `SKU ${item.externalSku}: ${displayName}`,
                    value: item.externalSku,
                };
            })
        );
    };

    let getItemMaster = async () => {
        let itemMasterRes = await warehouseApi.getItemMaster(true, true);

        if (itemMasterRes.status === false) return;

        const items = itemMasterRes.data.rows.map((item) => {
            return {
                value: item.sku,
                text: `SKU ${item.sku}: ${item.itemNameInternal}`,
                externalSku: item.externalSku,
            };
        });

        setItemMaster(itemMasterRes.data.rows);
        setItemMasterFullOptions(items);
        setItemMasterOptions(items);
    };

    let handleExternalSkuChange = async (event, value) => {
        if (!value) {
            setItemMasterOptions(itemMasterFullOptions);
            return;
        }

        setItemMasterOptions(
            itemMasterFullOptions.filter((itemMasterFullOption) => {
                return itemMasterFullOption.externalSku === value.value;
            })
        );
    };

    let handleItemFormChange = (formData) => {
        if (formData.week !== weekNumber) {
            formData.week = weekNumber;
        }

        if (!formData.externalSku && formData.itemMasterSKU) {
            let foundItemMaster = itemMaster.find((item) => item.sku === formData.itemMasterSKU.value);

            formData.externalSku = externalSkuOptions.find((item) => {
                return item.value === foundItemMaster.externalSku;
            });

            setItemMasterOptions(
                itemMasterFullOptions.filter((itemMasterFullOption) => {
                    return itemMasterFullOption.externalSku === foundItemMaster.externalSku;
                })
            );
        }

        if (formData.externalSku && formData.itemMasterSKU) {
            setDisableExternalSkuAutoComplete(true);
        }
    };

    let handleCheckActiveAndPrintedExpDate = async (event, value) => {
        if (!value) {
            setDisableExternalSkuAutoComplete(false);
            return;
        }

        let itemSku = value.value;
        let findItem = itemMaster.find((item) => item.sku === itemSku);
        setCurrentItem(findItem);

        let schedDate = moment(schedDeliveryDate);

        if (findItem.expirationType === 'EXPIRATION_DATE') {
            setHasPrintedExpDate(true);
            if (
                schedDeliveryDate &&
                (purchaseType === 'EVERYDAY' || purchaseType === 'PRIVATE_BRAND' || purchaseType === 'SEASONAL')
            ) {
                setExpExpireDate(
                    schedDate.add(currentItem.vendorGuaranteedDaysUponDelivery, 'days').format('YYYY-MM-DD')
                );
            }
        } else {
            setHasPrintedExpDate(false);
        }

        if (findItem.expirationType === 'PRODUCTION_DATE') {
            setHasPrintedProdDate(true);
            if (schedDeliveryDate) {
                setExpProdDate(
                    schedDate
                        .add(findItem.vendorGuaranteedDaysUponDelivery, 'days')
                        .subtract(findItem.expireLifeDays, 'days')
                        .format('YYYY-MM-DD')
                );
            }
        } else {
            setHasPrintedProdDate(false);
        }

        if (findItem.active === false) {
            setInactiveSku(true);
            setNotification({ text: 'Can not place PO against inactive SKU ' + findItem.sku, level: 'error' });
        } else {
            setInactiveSku(false);
        }
    };

    // Add item submit function
    let addItemSubmit = async (formData) => {
        let response = await warehouseApi.getItemMasterDetails(formData.itemMasterSKU);
        if (response.status === false) {
            let message = 'Error adding item';
            if (response.msg) {
                message += ': ' + response.msg;
            }
            setNotification({ text: message, level: 'error' });
        } else {
            let respObj = response.data.output;

            // Add to data table
            let sellItem = {};
            sellItem.sku = respObj.sku;
            sellItem.itemMasterName = respObj.itemNameInternal;
            sellItem.qty = formData.itemQty;
            sellItem.unitPrice = formData.itemUnitPrice;
            sellItem.vendorSku = formData.itemVendorSKU;
            sellItem.csPallet = respObj.csPallet;
            sellItem.itemTotal = '$' + formData.itemUnitPrice * formData.itemQty;
            sellItem.expectedExpirationDate = expExpireDate;
            sellItem.expectedProductionDate = expProdDate;
            sellItem.week = formData.week;
            sellItem.year = formData.year;
            sellItem.purchaseType = formData.purchaseType;
            sellItem.suggestedRetailPrice = formData.msrp ? formData.msrp : 0;
            if (sellItem.suggestedRetailPrice === 0) {
                sellItem.margin = 'N/A';
            } else {
                sellItem.margin = parseFloat(
                    ((formData.msrp - formData.itemUnitPrice / respObj.baseUnitsPerCase) / formData.msrp) * 100
                ).toFixed(2);
            }
            sellItem.dtc = parseFloat(((respObj.msrp - formData.msrp) / respObj.msrp) * 100).toFixed(2);

            if (sellItem.dtc < 10) {
                setDtcIssueCount((count) => count + 1);
            }

            if (sellItem.margin !== 'N/A') {
                sellItem.margin = sellItem.margin + '%';
            }
            sellItem.dtc = sellItem.dtc + '%';

            // Append price / SKU if available
            let poResponse = await api.getLastPOItemFromVendor(vendor.value, respObj.sku);
            if (poResponse.status === true) {
                if (poResponse.data.rows.length > 0) {
                    //sellItem.unitPrice = poResponse.data.rows[0].unitPrice;
                    sellItem.itemVendorSKU = poResponse.data.rows[0].vendorSKU;
                }
            }

            if (sellItem.unitPrice === '') {
                setNotification({ text: 'Could not find price, and price not specified', level: 'error' });
                return;
            }

            if (sellItem.itemVendorSKU === '') {
                setNotification({ text: 'Could not find vendor SKU, and vendor SKU not specified', level: 'error' });
                return;
            }

            sellItem.sourcingReasons = formData.sourcingReasons.map((i) => i.value);
            sellItem.unpurchasedOutcome = formData.wwig;
            sellItem.countryOfOriginId = formData.countryOfOriginId;

            if (!sellItem.week && originalRequestedDeliveryDate) {
                sellItem.week = dateHelper.getWeekNumber(originalRequestedDeliveryDate, 'YYYY-MM-DD');
            }

            let items = itemsInPO.concat(sellItem);
            setItemsInPO(items);
        }
    };

    // Remove an item from the PO
    let removeItemPressed = async (row) => {
        if (row.original.dtc < 10) {
            setDtcIssueCount((count) => count - 1);
        }

        var array = [...itemsInPO];
        array.splice(row.index, 1);
        setItemsInPO(array);
    };

    useEffect(() => {
        getExternalSku();
        getItemMaster();
        if (initialValue.length) {
            setFormField(initialValue);
        }
        getItemTypes();
    }, []);

    useEffect(() => {
        setFormField(itemsInPO);

        let total = 0;

        if (initValue.length > 0) {
            setDtcIssueCount(0);
        }

        itemsInPO.map((item) => {
            if (item.itemTotal) {
                total += parseFloat(item.itemTotal.substring(1));
            } else {
                //duplicate PO route
                total += parseFloat(parseFloat(item.unitPrice) * item.qty);
                if (item.suggestedRetailPrice === 0) {
                    item.margin = 'N/A';
                } else {
                    item.margin = parseFloat(
                        ((item.suggestedRetailPrice - parseFloat(item.unitPrice) / item.baseUnitsPerCase) /
                            item.suggestedRetailPrice) *
                            100
                    ).toFixed(2);
                }
                item.dtc = parseFloat(((item.msrpPrice - item.suggestedRetailPrice) / item.msrpPrice) * 100).toFixed(2);

                if (item.dtc < 10) {
                    setDtcIssueCount((count) => count + 1);
                }

                if (item.margin !== 'N/A') {
                    item.margin = item.margin + '%';
                }

                item.dtc = item.dtc + '%';
                item.itemTotal = '$' + parseFloat(item.unitPrice) * item.qty;
                return item;
            }
        });
        setPoTotal(total);
    }, [itemsInPO]);

    let purchaseOrderItemFormFields = [
        {
            name: 'externalSku',
            inputElement: 'autocomplete',
            label: 'External SKU (filter)',
            gridValue: 4,
            inputProps: {
                required: false,
                disabled: disableExternalSkuAutoComplete,
                opts: externalSkuOptions,
                onChange: handleExternalSkuChange,
            },
        },
        {
            name: 'itemMasterSKU',
            inputElement: 'autocomplete',
            label: 'Item Master',
            gridValue: 6,
            inputProps: { required: true, opts: itemMasterOptions, onChange: handleCheckActiveAndPrintedExpDate },
        },
        {
            name: 'itemQty',
            inputElement: 'textField',
            label: 'Quantity (cases)',
            gridValue: 2,
            inputProps: { required: true },
        },
        {
            name: 'itemUnitPrice',
            inputElement: 'textField',
            label: 'Case Price',
            gridValue: 2,
            inputProps: { required: true },
        },
        { name: 'itemVendorSKU', inputElement: 'textField', label: 'Vendor SKU', gridValue: 2 },
        { name: 'week', inputElement: 'textField', label: 'Week', gridValue: 1 },
        { name: 'year', inputElement: 'textField', label: 'Year', gridValue: 1 },
        {
            name: 'purchaseType',
            inputElement: 'select',
            label: 'Purchase Type',
            gridValue: 2,
            inputProps: {
                opts: purchaseOpts,
                required: true,
                onChange: (e) => setPurchaseType(e.target.value),
            },
        },
        {
            name: 'msrp',
            inputElement: 'textField',
            label: 'SRP',
            gridValue: 2,
            inputProps: { type: 'number', min: '0' },
        },
        {
            name: 'expectedExpirationDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Expected Expiration Date',
            gridValue: 3,
            dependencies: ['itemMasterSKU'],
            inputProps: {
                required: hasPrintedExpDate ? true : false,
                disabled: hasPrintedExpDate ? false : true,
                style: hasPrintedExpDate ? {} : { background: 'lightgrey', opacity: '50%' },
                onChange: (e) => setExpExpireDate(e.target.value),
            },
            value: hasPrintedExpDate ? expExpireDate : undefined,
        },
        {
            name: 'expectedProductionDate',
            inputElement: 'date',
            disableTextInput: false,
            label: 'Expected Production Date',
            gridValue: 3,
            dependencies: ['itemMasterSKU'],
            inputProps: {
                required: hasPrintedProdDate ? true : false,
                disabled: hasPrintedProdDate ? false : true,
                style: hasPrintedProdDate ? {} : { background: 'lightgrey', opacity: '50%' },
                onChange: (e) => setExpProdDate(e.target.value),
            },
            value: hasPrintedProdDate ? expProdDate : undefined,
        },
        {
            name: 'wwig',
            inputElement: 'select',
            label: 'Where Would It Go?',
            gridValue: 2,
            inputProps: {
                opts: unpurchasedOutcomes,
                required: false,
            },
        },
        {
            name: 'sourcingReasons',
            inputElement: 'autocomplete',
            label: "Why I'm a Misfit",
            gridValue: 3,
            inputProps: {
                opts: sourcingReasons,
                multiple: true,
                required: false,
            },
        },
        {
            name: 'countryOfOriginId',
            inputElement: 'select',
            label: 'Country of Origin',
            gridValue: 2,
            inputProps: {
                opts: countries,
                required: false,
            },
        },
    ];

    let purchaseOrderItemColumns = [
        { accessor: 'sku', Header: 'SKU' },
        { accessor: 'itemMasterName', Header: 'Name' },
        {
            accessor: 'qty',
            Header: 'Quantity',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        { accessor: 'vendorSku', Header: 'Vendor SKU', editable: true },
        {
            accessor: 'csPallet',
            Header: 'Cases per pallet',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'unitPrice',
            Header: 'Case Cost',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        { accessor: 'itemTotal', Header: 'Total' },
        {
            accessor: 'expectedExpirationDate',
            Header: 'Expected Expiration Date',
            editable: (row) => row.original.expirationType === 'EXPIRATION_DATE',
            editProps: {
                type: 'input',
                disableTextInput: true,
                inputType: 'date',
                required: (row) => row.original.expirationType === 'EXPIRATION_DATE',
            },
        },
        {
            accessor: 'expectedProductionDate',
            Header: 'Expected Production Date',
            editable: (row) => row.original.expirationType === 'PRODUCTION_DATE',
            editProps: {
                type: 'input',
                disableTextInput: true,
                inputType: 'date',
                required: (row) => row.original.expirationType === 'PRODUCTION_DATE',
            },
        },
        {
            accessor: 'week',
            Header: 'Week Number',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true }
        },
        {
            accessor: 'year',
            Header: 'Year',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: true },
        },
        {
            accessor: 'purchaseType',
            Header: 'Purchase Type',
            editable: true,
            editProps: { type: 'select', options: editPurchaseOpts, required: true },
        },
        {
            accessor: 'suggestedRetailPrice',
            Header: 'SRP',
            editable: true,
            editProps: { type: 'input', inputType: 'number', required: false },
        },
        { accessor: 'margin', Header: 'Margin' },
        { accessor: 'dtc', Header: 'DTC' },
        {
            accessor: 'unpurchasedOutcome',
            Header: 'Where Would It Go?',
            editable: true,
            editProps: { type: 'select', options: unpurchasedOutcomes },
        },
        {
            accessor: 'sourcingReasons',
            Header: "Why I'm a Misfit",
            editable: true,
            editProps: {
                type: 'autocomplete-multiple',
                options: sourcingReasons,
                multiple: true,
            },
            Cell: ({
                row: {
                    values: { sourcingReasons },
                },
            }) => (sourcingReasons ? sourcingReasons.join(', ') : ''),
        },
        {
            accessor: 'countryOfOriginId',
            Header: 'Country of Origin',
            editable: true,
            editProps: { type: 'select', options: countries },
        },
        {
            accessor: 'remove',
            Header: 'Remove',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Remove_${row.id}`}
                        onClick={() => {
                            removeItemPressed(row);
                        }}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <h3>Items In PO</h3>
            <FormComponent
                formFields={purchaseOrderItemFormFields}
                onSubmit={async (formData, resetForm) => {
                    addItemSubmit(formData);
                    setExpProdDate();
                    setExpExpireDate();
                    setPurchaseType();
                    setCurrentItem();
                    setItemMasterOptions(itemMasterFullOptions);
                    setDisableExternalSkuAutoComplete(false);
                    resetForm();
                }}
                onFormDataChange={handleItemFormChange}
                button={{
                    text: 'Add',
                    gridValue: 1,
                    disabled: (formData) => {
                        if (!formData.itemMasterSKU || inactiveSku) {
                            return true;
                        }
                        return false;
                    },
                }}
            />
            <DataTable
                columns={purchaseOrderItemColumns}
                data={itemsInPO}
                editable={true}
                saveEdit={({ id, field, value, row }) => {
                    let items = JSON.parse(JSON.stringify(itemsInPO)).map((item) => {
                        if (item.sku === row.sku) {
                            if (field === 'qty') {
                                item.itemTotal = '$' + item.unitPrice * value;
                            }
                            if (field === 'unitPrice') {
                                item.itemTotal = '$' + item.qty * value;
                            }
                            item[field] = value;
                        }
                        return item;
                    });
                    setItemsInPO(items);
                }}
            />
        </div>
    );
};

export { CreatePO };
