import React, { useState, useEffect, useContext } from 'react';
import { Notifications, DataTable, WholesaleAccountPicker } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { Button } from '@material-ui/core';
import { UserContext } from '../../../contexts/UserContext';

let ViewGoPuffPackout = () => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    const user = useContext(UserContext);
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);


    let getRows = async () => {
        let response = await api.getPackoutRows(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows ? response.data.rows : []);
    };

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    useEffect(() => {
        if (!!wholesaleAccount.id) {
            getRows();
        }
    }, [api]);

    let cols = [
        { accessor: 'packDate', Header: 'Pack Date' },
        { accessor: 'count', Header: 'Num Shipments' },
        {
            accessor: 'download',
            Header: 'Download',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <Button
                        id={`Download`}
                        onClick={async () => {
                            let res = await api.sendPackoutFile(user.getFacilityID(), row.original.packDate);
                            if (!res.status) {
                                setNotification({ text: 'Failed to send packout file. ' + res.msg, level: 'error' });
                                return;
                            }
                            setNotification({ text: 'Pack out file processing. Wait for email', level: 'success' });
                        }}
                    >
                        Send File
                    </Button>
                );
            },
        },
    ];

    return (
        <div>
            <WholesaleAccountPicker />
            {!!wholesaleAccount.id && (
                <>
                    <h1>{wholesaleAccount.companyName} Packout files</h1>
                    <h2>Your file will be emailed to you. Please wait 10-15 minutes for completion.</h2>
                        <Notifications options={notification} />
                    <div>
                        <DataTable columns={cols} data={rows} />
                    </div>
                </>
            )}
        </div>
    );
};

export { ViewGoPuffPackout };
