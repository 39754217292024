import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { UserContext } from '../../../contexts/UserContext';

let WholesaleOrderUpload = () => {
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    let columns = [
        { Header: 'Delivery Date', accessor: 'deliveryDate' },
        { Header: 'Market', accessor: 'market' },
        { Header: 'Micro FC', accessor: 'microFC' },
        { Header: 'PO Num', accessor: 'poNum' },
        { Header: 'External Sku', accessor: 'externalSku' },
        { Header: wholesaleAccount.companyName+' Sku', accessor: wholesaleAccount.fbmAccountName+'Sku' },
        { Header: 'Product Name', accessor: 'productName' },
        { Header: 'Qty', accessor: 'qty' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmOrderUpload(key);
        return res;
    };

    let uploadOrders = async (formData) => {
        let res = await api.uploadOrders(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Delivery Date,Market,';
        csvContent += `Micro FC,PO Num,External Sku,${wholesaleAccount.companyName} Sku,Product Name,Qty`;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', wholesaleAccount.fbmAccountName+'_order_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <Link to={'/gopuff/orders'}>View Orders</Link>
            <UploadCSVAndReviewComponent
                title={`Upload ${wholesaleAccount.companyName} Orders`}
                fileLabel="Order File"
                columns={columns}
                upload={uploadOrders}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { WholesaleOrderUpload };
