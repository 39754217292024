import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Notifications, DataTable, FormComponent } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import {
    Modal,
    Button,
    Select,
    NativeSelect,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Paper,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';
import moment from 'moment';

// The table to show details
const BulkPackSlipsAndBOLsTable = ({ lineHauls, facilityID, wholesaleAccount, api }) => {
    let [lineHaulName, setLineHaulName] = useState(null);
    let [deliveryDate, setDeliveryDate] = useState(moment().add(1, 'days').format('YYYY-MM-DD'));
    let [notification, setNotification] = useState({ text: null, level: null });
    let [loading, setLoading] = useState(false);
    let [dataLoaded, setDataLoaded] = useState([]);
    let [dataIsReady, setDataIsReady] = useState(false);
    let [expanded, setExpanded] = useState(false);

    let handleDateChange = (e) => {
        setDeliveryDate(e.target.value);
    };

    let reset = async () => {
        setDataLoaded([]);
        setLoading(false);
        setLineHaulName(null);
        setDataIsReady(false);
        setExpanded(false);
    };

    useEffect(() => {
        if (lineHaulName !== null && dataLoaded.length > 0) {
            setDataIsReady(true);
            setLoading(false);
            setExpanded(true);
        }
    }, [lineHaulName, dataLoaded]);

    return (
        <div>
            <Notifications options={notification} />
            <Paper style={{ padding: '30px' }}>
                <TextField
                    id="deliveryDate"
                    label="Delivery Date"
                    type="date"
                    value={deliveryDate}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={loading}
                    onChange={handleDateChange}
                    style={{ marginRight: '50px', padding: '10px', border: '1px solid gray', borderRadius: '10px' }}
                />
                <Button
                    variant="outlined"
                    disabled={loading}
                    style={{ background: '#4050B5', color: '#FFFFFF' }}
                    onClick={() => {
                        setDeliveryDate(deliveryDate);
                    }}
                >
                    Go
                </Button>
            </Paper>
            {lineHauls.map((lh) => {
                return (
                    <Grid container>
                        <Grid item md={12} style={{ textAlign: 'left', padding: '10px' }}>
                            <Button
                                id={lh.lineHaulName}
                                disabled={loading}
                                variant="outlined"
                                style={{ background: '#4050B5', color: '#FFFFFF' }}
                                onClick={async () => {
                                    if (expanded === true) {
                                        setExpanded(false);
                                        reset();
                                        return;
                                    }
                                    setExpanded(!expanded);
                                    setDataIsReady(false);
                                    setLoading(true);
                                    setLineHaulName(lh.lineHaulName);
                                    let response = await api.getOrdersByLineHauls(
                                        facilityID,
                                        lh.lineHaulName,
                                        deliveryDate
                                    );
                                    if (response.status === false) {
                                        setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
                                        reset();
                                        return;
                                    }

                                    if (response.data.rows && response.data.rows.length === 0) {
                                        setNotification({
                                            text: 'No orders for line haul ' + lh.lineHaulName,
                                            level: 'success',
                                        });
                                        reset();
                                        return;
                                    }

                                    let lhData = response.data.rows;
                                    setDataLoaded(lhData);
                                }}
                            >
                                {' '}
                                {expanded === true && lineHaulName === lh.lineHaulName ? '-' : '+'}{' '}
                            </Button>
                            <strong>
                                <span style={{ paddingLeft: '10px', paddingRight: '40px' }}> {lh.lineHaulName} </span>
                            </strong>{' '}
                            <Button
                                variant="outlined"
                                disabled={loading}
                                style={{ background: '#4050B5', color: '#FFFFFF', marginRight: '30px' }}
                                onClick={async () => {
                                    setLoading(true);
                                    setLineHaulName(lh.lineHaulName);

                                    let res = await api.bulkDownloadPackSlips(
                                        facilityID,
                                        lh.lineHaulName,
                                        deliveryDate
                                    );
                                    if (res && res.status === true) {
                                        setLoading(false);
                                        reset();
                                    } else {
                                        setNotification({ text: 'Failed to download PDF: ' + res.msg, level: 'error' });
                                        reset();
                                    }
                                }}
                            >
                                Bulk Pack Slips
                            </Button>{' '}
                            <Button
                                variant="outlined"
                                disabled={loading}
                                style={{ background: '#4050B5', color: '#FFFFFF' }}
                                onClick={async () => {
                                    setLoading(true);
                                    setLineHaulName(lh.lineHaulName);
                                    let res = await api.bulkDownloadBOLs(facilityID, lh.lineHaulName, deliveryDate);

                                    if (res && res.status === true) {
                                        setLoading(false);
                                        reset();
                                    } else {
                                        setNotification({
                                            text: 'Failed to download HTML: ' + res.msg,
                                            level: 'error',
                                        });
                                        reset();
                                    }
                                }}
                            >
                                Bulk BOLs
                            </Button>
                        </Grid>
                        {expanded === true && dataIsReady === true && lineHaulName === lh.lineHaulName && (
                            <Grid item md={12}>
                                <ViewLineHaulDetailsTable 
                                data={dataLoaded} 
                                wholesaleAccount={wholesaleAccount} 
                                api={api}
                            />
                            </Grid>
                        )}
                    </Grid>
                );
            })}
        </div>
    );
};

// The table to show details
const ViewLineHaulDetailsTable = ({ data, wholesaleAccount, api }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [selectedOrderID, setSelectedOrderID] = useState(0);
    let [markDeliveredModalOpen, setMarkDeliveredModalOpen] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (data.length > 0) {
            setRows(data);
        }
    }, [data]);

    let columns = [
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'orderNum', Header: 'Order Num' },
        { accessor: 'status', Header: 'Shipment Status' },
        { accessor: 'microFC', Header: 'Micro FC' },
        { accessor: 'poNum', Header: 'PO#' },
        { accessor: 'createdAt', Header: 'Created At' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        {
            accessor: 'packSlip',
            Header: 'Pack Slip',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Print`}
                            onClick={async () => {
                                api.downloadPackSlip(row.original.shipmentID);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'bol',
            Header: 'BOL',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Print`}
                            onClick={async () => {
                                api.downloadBOL(row.original.shipmentID);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'markDelivered',
            Header: 'Mark Delivered',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Delivered`}
                            onClick={() => {
                                setSelectedOrderID(row.original.orderNum);
                                setMarkDeliveredModalOpen(true);
                            }}
                        >
                            Mark Delivered
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'issueCredit',
            Header: 'Credits',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Credit`}
                            onClick={async () => {
                                history.push(`/gopuff/issue-po-credits?poNum=${row.original.poNum}`);
                            }}
                        >
                            Issue Credit
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <Notifications options={notification} />
            {markDeliveredModalOpen && (
                <MarkDeliveredModal
                    setModalOpen={setMarkDeliveredModalOpen}
                    selectedRow={selectedOrderID}
                    setNotification={setNotification} 
                    api={api}
                />
            )}
            <DataTable columns={columns} data={rows} expandable={true} ExpansionComponent={<ViewDetailsTable wholesaleAccount={wholesaleAccount} api={api} />} />
        </div>
    );
};

// The table to show details
const ViewDetailsTable = ({ parentRow, wholesaleAccount, api }) => {
    let [rows, setRows] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });

    // Load  table
    let getRows = async () => {
        let response = await api.getShipmentDetails(parentRow.original.shipmentID);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.details.picks ? response.data.details.picks : []);
    };

    useEffect(() => {
        getRows();
    }, []);

    let columns = [
        { accessor: 'externalSku', Header: 'External SKU' },
        { accessor: 'thirdPartySku', Header: wholesaleAccount.companyName+' SKU' },
        { accessor: 'name', Header: 'Product Name' },
        { accessor: 'qty', Header: 'Request Pick Qty' },
        { accessor: 'numPicked', Header: 'Actual Pick Qty' },
    ];

    return (
        <div>
            <Notifications options={notification} />
            <DataTable columns={columns} data={rows} />
        </div>
    );
};

let MarkDeliveredModal = ({ selectedRow, setModalOpen, setNotification, getRows, api }) => {
    let formFields = [
        {
            label: 'Delivery Date',
            inputElement: 'date',
            name: 'deliveryDate',
            gridValue: 6,
            inputProps: { required: true },
        },
    ];

    return (
        <Dialog
            open={true}
            onClose={() => {
                setModalOpen(false);
            }}
        >
            <DialogTitle>Mark Order {selectedRow} Delivered</DialogTitle>
            <FormComponent
                formFields={formFields}
                onSubmit={async (formData, resetForm) => {
                    let sendParams = {
                        orderID: selectedRow,
                        deliveryDate: formData.deliveryDate,
                    };

                    let res = await api.markDelivered(sendParams);
                    if (!res.status) {
                        setNotification({ text: 'Failed to mark delivered. ' + res.msg, level: 'error' });
                        return;
                    }
                    setNotification({ text: 'Order marked delivered', level: 'success' });
                    if (getRows instanceof Function) {
                        getRows();
                    }
                    setModalOpen(false);
                }}
            />
            <DialogActions>
                <Button onClick={() => setModalOpen(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

let ViewWholesaleOrders = ({ wholesaleAccount }) => {
    let [rows, setRows] = useState([]);
    let [lineHauls, setLineHauls] = useState([]);
    let [notification, setNotification] = useState({ text: null, level: null });
    let [markDeliveredModalOpen, setMarkDeliveredModalOpen] = useState(false);
    let [selectedOrderID, setSelectedOrderID] = useState(0);
    const [startDate, setStartDate] = useState(moment().subtract(14, 'days').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
    const [loading, setLoading] = useState(false);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);

    const history = useHistory();
    const user = useContext(UserContext);

    // Load  line hauls
    let getLineHauls = async () => {
        let response = await api.getLineHaulsWithOrders(user.getFacilityID());
        if (response.status === false) {
            setNotification({ text: 'No line hauls found ' + response.msg, level: 'error' });
            setLineHauls([]);
            return;
        }

        setLineHauls(response.data.rows ? response.data.rows : []);
    };

    // Load  table
    let getRows = async () => {
        setLoading(true);
        let response = await api.getOrdersWithinRange(user.getFacilityID(), startDate, endDate);
        setLoading(false);
        if (response.status === false) {
            setNotification({ text: 'No entries found ' + response.msg, level: 'error' });
            setRows([]);
            return;
        }

        setRows(response.data.rows ? response.data.rows : []);
    };

    const handleStartDateChange = (e) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    const handleDateFiltersApply = () => {
        getRows();
    };

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    useEffect(() => {
        getRows();
        getLineHauls();
    }, [api]);

    let columns = [
        { accessor: 'shipmentID', Header: 'Shipment ID' },
        { accessor: 'orderNum', Header: 'Order Num' },
        { accessor: 'status', Header: 'Shipment Status' },
        { accessor: 'microFC', Header: 'Micro FC' },
        { accessor: 'poNum', Header: 'PO#' },
        { accessor: 'createdAt', Header: 'Created At' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        {
            accessor: 'packSlip',
            Header: 'Pack Slip',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Print`}
                            onClick={async () => {
                                api.downloadPackSlip(row.original.shipmentID);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'bol',
            Header: 'BOL',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Print`}
                            onClick={async () => {
                                api.downloadBOL(row.original.shipmentID);
                            }}
                        >
                            Download
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'markDelivered',
            Header: 'Mark Delivered',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Delivered`}
                            onClick={async () => {
                                setSelectedOrderID(row.original.orderNum);
                                setMarkDeliveredModalOpen(true);
                            }}
                        >
                            Mark Delivered
                        </Button>
                    </div>
                );
            },
        },
        {
            accessor: 'issueCredit',
            Header: 'Credits',
            Cell: ({ cell: { value: initialValue }, row, column, saveEdit, editable }) => {
                return (
                    <div
                        onClick={(event) => {
                            event.stopPropagation();
                        }}
                    >
                        <Button
                            id={`Credit`}
                            onClick={async () => {
                                history.push(`/gopuff/issue-po-credits?poNum=${row.original.poNum}`);
                            }}
                        >
                            Issue Credit
                        </Button>
                    </div>
                );
            },
        },
    ];

    return (
        <div>
            <div>
                <Notifications options={notification} />
                <h1>{wholesaleAccount.companyName} Orders</h1>
                <Grid item md={6} style={{ textAlign: 'left', paddingLeft: '40px' }}>
                    <h2>Bulk Pack Slips and BOLs</h2>
                    <h2>Grouped by Line Haul</h2>
                </Grid>
                <BulkPackSlipsAndBOLsTable 
                    lineHauls={lineHauls} 
                    facilityID={user.getFacilityID()} 
                    wholesaleAccount={wholesaleAccount} 
                    api={api}
                />
            </div>
            {markDeliveredModalOpen && (
                <MarkDeliveredModal
                    setModalOpen={setMarkDeliveredModalOpen}
                    selectedRow={selectedOrderID}
                    setNotification={setNotification}
                    getRows={getRows} 
                    api={api}
                />
            )}

            <Notifications options={notification} />
            <Grid style={{ margin: '50px' }}></Grid>
            <div>
                <Grid item>
                    <TextField
                        id="reportStartDate"
                        label="Start Date"
                        type="date"
                        value={startDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={loading}
                        onChange={handleStartDateChange}
                        style={{ marginRight: '50px' }}
                    />

                    <TextField
                        id="reportEndDate"
                        label="End Date"
                        type="date"
                        value={endDate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled={loading}
                        onChange={handleEndDateChange}
                        style={{ marginRight: '50px' }}
                    />
                    <Button onClick={handleDateFiltersApply} color="secondary" disabled={loading}>
                        Apply
                    </Button>
                </Grid>
                <Grid item style={{ marginTop: 12 }}>
                    <Link to={'/gopuff/order-upload'}>Upload</Link>
                </Grid>
                <h1>Individual Orders</h1>
                <DataTable 
                    columns={columns} 
                    data={rows} 
                    expandable={true} 
                    ExpansionComponent={
                        <ViewDetailsTable 
                            wholesaleAccount={wholesaleAccount} 
                            api={api} 
                        />}
                />
            </div>
        </div>
    );
};

export { ViewWholesaleOrders };
