import React, { useState, useEffect, useContext } from 'react';
import { Button } from '@material-ui/core';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { Notifications, DataTable } from '../../../shared';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../../contexts/UserContext';

let ReviewWholesaleCredits = (props) => {
    let [notification, setNotification] = useState({ text: null, level: null });
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);
    const history = useHistory();
    const rows = props.location.state?.rows || [];

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    let columns = [
        { accessor: 'poNum', Header: 'PO#' },
        { accessor: 'microFC', Header: 'DD MFC' },
        { accessor: 'deliveryDate', Header: 'Delivery Date' },
        { accessor: 'externalSKU', Header: 'External SKU' },
        { accessor: 'name', Header: 'Product Name' },
        { accessor: 'numOrdered', Header: '# Ordered' },
        { accessor: 'pricePerSKU', Header: 'Price Per SKU' },
        { accessor: 'numToCredit', Header: '# Credit' },
        { accessor: 'dollarToCredit', Header: '$ Credit' },
        { accessor: 'reason', Header: 'Reason' },
    ];

    const handleBackButton = () => {
        history.goBack();
    };

    const handleIssueCreditButton = async () => {
        let response = await api.issuePOCredit(rows);

        if (response.status === false) {
            setNotification({ text: 'Failed: ' + response.msg, level: 'error' });
            return;
        }

        setNotification({ text: 'Credits issued to '+wholesaleAccount.companyName, level: 'success' });
    };

    return (
        <div>
            <h1>Review {wholesaleAccount.companyName} Credits</h1>
            <Notifications options={notification} />
            <div>
                <DataTable columns={columns} data={rows} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                <Button variant="contained" color="primary" onClick={handleBackButton}>
                    Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleIssueCreditButton}>
                    Issue Credits
                </Button>
            </div>
        </div>
    );
};

export { ReviewWholesaleCredits };
