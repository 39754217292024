import React, { useReducer, useEffect, createContext, useState } from 'react';
import { setSentryUser, clearSentryUser } from '../utils/sentryUtils';
import { api } from '../api/api';

const userReducer = (user, newUser) => {
    if (!newUser) {
        localStorage.removeItem('user');
        return {};
    }

    return { ...user, ...newUser };
};

const fbmReducer = (account, newAccount) => {
    if (!newAccount) {
        localStorage.removeItem('fbmAccount');
        return {};
    }

    return { ...account, ...newAccount };
};

const famReducer = (account, newAccount) => {
    if (!newAccount) {
        localStorage.removeItem('famAccount');
        return {};
    }

    return { ...account, ...newAccount };
};

const wholesaleReducer = (account, newAccount) => {
    if (!newAccount) {
        localStorage.removeItem('wholesaleAccount');
        return {};
    }

    return { ...account, ...newAccount };
};

const initialUser = JSON.parse(localStorage.getItem('user'));
const initialFbmAccount = JSON.parse(localStorage.getItem('fbmAccount'));
const initialFamAccount = JSON.parse(localStorage.getItem('famAccount'));
const initialWholesaleAccount = JSON.parse(localStorage.getItem('wholesaleAccount'));
const UserContext = createContext();

/** {
 * id,
 * language,
 * facilityID,
 * facilityName,
 * name,
 * canRefundPastDate,
 * canAdjustCount,
 * canAdjustInvFromDesktop,
 * }
 *
 */
const UserProvider = ({ children }) => {
    const [user, setUser] = useReducer(userReducer, initialUser || {});
    const [roles, setRoles] = useState([]);
    const [fbmAccount, setFbmAccount] = useReducer(fbmReducer, initialFbmAccount || {});
    const [famAccount, setFamAccount] = useReducer(famReducer, initialFamAccount || {});
    const [wholesaleAccount, setWholesaleAccount] = useReducer(
        wholesaleReducer,
        initialWholesaleAccount ||
        {
            id: 6,
            companyName: 'Go Puff',
            fbmAccountName: 'gopuff',
            facilityIds: [9,10,11,12,13]
        }
    );

    useEffect(() => {
        if (!!user?.id) {
            setSentryUser(user.id, user.name);
            localStorage.setItem('user', JSON.stringify(user));
        }
    }, [user]);

    useEffect(() => {
        if (!!fbmAccount?.id) {
            localStorage.setItem('fbmAccount', JSON.stringify(fbmAccount));
        }
    }, [fbmAccount]);

    useEffect(() => {
        if (!!famAccount?.partnershipSource) {
            localStorage.setItem('famAccount', JSON.stringify(famAccount));
        }
    }, [famAccount]);

    useEffect(() => {
        if (!!wholesaleAccount?.id) {
            localStorage.setItem('wholesaleAccount', JSON.stringify(wholesaleAccount));
        }
    }, [wholesaleAccount]);

    useEffect(() => {
        getRoles();
    }, []);

    let getRoles = async () => {
        let res = await api.getRoles();
        if (!res.status) {
            clearUserAndLogOut();
            return;
        }

        let userData = res?.data?.user || {};
        setUserAttributes(userData);

        setRoles(res.data.rolesLong);
    };

    const clearUserAndLogOut = () => {
        setUser(null);
        setFbmAccount(null);
        setFamAccount(null);
        setWholesaleAccount(null);
        clearSentryUser();
        api.logOut();
    };

    const setLanguage = (language) => {
        setUser({ language });
    };

    const setFacilityIDAndName = ({ facilityID, facilityName }) => {
        setUser({ facilityName, facilityID });
    };

    const setUserAttributes = (user) => {
        if (user.fbm) {
            const { id, name, fbmAccountName } = user;
            setUser({ id, name, fbmAccountName, fbm: true, famPartner: false });
        } else if (user.fam) {
            const { id, name, partnershipSource, facilities } = user;
            setFamAccount({ partnershipSource: partnershipSource, facilities: facilities });
            setUser({ id, name, partnershipSource, famPartner: true });
        } else {
            const {
                id,
                language,
                facilityCluster,
                facilityClusterIds,
                facilityID,
                facilityName,
                name,
                canRefundPastDate,
                canAdjustCount,
                canAdjustInvFromDesktop,
                canBulkUpdateTaxonomy,
                homeFacility,
            } = user;
            setUser({
                id,
                language,
                facilityCluster,
                facilityClusterIds,
                facilityID,
                facilityName,
                name,
                canRefundPastDate,
                canAdjustCount,
                canAdjustInvFromDesktop,
                canBulkUpdateTaxonomy,
                fbm: false,
                famPartner: false,
                homeFacility,
            });
        }
    };

    // MM users only
    const getFacilityCluster = () => {
        return user?.facilityCluster || null;
    };
    const getFacilityClusterIds = () => {
        return user?.facilityClusterIds || null;
    };
    const getFacilityID = () => {
        return user?.facilityID || null;
    };
    const getHomeFacility = () => {
        return user?.homeFacility || null;
    };
    const canAdjustCount = () => {
        return user?.canAdjustCount || false;
    };
    const canRefundPastDate = () => {
        return user?.canRefundPastDate || false;
    };
    const getFacilityName = () => {
        return user?.facilityName || '';
    };
    const getUserEmail = () => {
        return user?.name || '';
    };
    const canChangeCartExperience = () => {
        return user?.rolesLong?.any((role) => role.name.endsWith('CHANGE_CART_EXPERIENCE')) ?? false;
    };
    const canAdjustInvFromDesktop = () => {
        return user?.canAdjustInvFromDesktop || false;
    };

    return (
        <UserContext.Provider
            value={{
                user,
                setUserAttributes,
                clearUserAndLogOut,
                setLanguage,
                setFacilityIDAndName,
                fbmAccount,
                setFbmAccount,
                getFacilityCluster,
                getFacilityClusterIds,
                getFacilityID,
                getFacilityName,
                getHomeFacility,
                getUserEmail,
                canRefundPastDate,
                canAdjustCount,
                canChangeCartExperience,
                roles,
                canAdjustInvFromDesktop,
                famAccount,
                setFamAccount,
                wholesaleAccount,
                setWholesaleAccount,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
