import React, { useState, useEffect } from 'react';
import { DataTable, UploadCSVAndReviewComponent } from '../../../shared';
import { procurementApi as api } from '../../../api/procurement';
import moment from 'moment';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';

const PO_ALERT_VALUE = 50_000;
const PO_WARNING_VALUE = 200_000;

const validationColumns = [{ Header: 'Errors', accessor: 'errors' }];

const purchaseOrderColumns = [
    { Header: 'Facility', accessor: 'facility' },
    { Header: 'Vendor Name', accessor: 'vendor' },
    { Header: 'PO Number', accessor: 'poNumber' },
    { Header: 'Shipper Name', accessor: 'shipper' },
    { Header: 'Cross Dock', accessor: 'crossDockName' },
    { Header: 'Cross Dock Delivery Date', accessor: 'crossDockDeliveryDate' },
    { Header: 'Delivery Type', accessor: 'deliveryType' },
    { Header: 'Pick Up Date', accessor: 'pickupDate' },
    { Header: 'Original Requested Delivery Date', accessor: 'initialDeliveryDate' },
    { Header: 'Scheduled Delivery Date', accessor: 'deliveryDate' },
    { Header: 'Min Temp', accessor: 'minTempReq' },
    { Header: 'Max Temp', accessor: 'maxTempReq' },
    { Header: 'Total Cost', accessor: 'totalCost' },
    { Header: 'Buyer 1 Email', accessor: 'buyer1' },
    { Header: 'Buyer 2 Email', accessor: 'buyer2' },
    { Header: 'Buyer 3 Email', accessor: 'buyer3' },
    { Header: 'Buyer 4 Email', accessor: 'buyer4' },
    { Header: 'Special Instructions', accessor: 'instructions' },
    { Header: 'Priority', accessor: 'priority', Cell: ({ row: { values } }) => values.priority.toString() },
    { Header: 'Freight Cost', accessor: 'estimatedFreightCost' },
    { Header: 'Errors', accessor: 'errors' },
];

const lineItemColumns = [
    { Header: 'CSV Line #', accessor: 'rowNumber' },
    { Header: 'Errors', accessor: 'errors' },
    ...purchaseOrderColumns,
    { Header: 'SKU', accessor: 'sku' },
    { Header: 'External SKU', accessor: 'externalSku' },
    { Header: 'Vendor SKU', accessor: 'vendorSku' },
    { Header: 'SKU Name', accessor: 'skuName' },
    { Header: 'Case QTY', accessor: 'caseQty' },
    { Header: 'Case Cost', accessor: 'unitPrice' },
    { Header: 'CS/Pallet', accessor: 'csPallet' },
    { Header: 'Week', accessor: 'week' },
    { Header: 'Year', accessor: 'year' },
    { Header: 'Purchase Type', accessor: 'purchaseType' },
    { Header: 'SRP', accessor: 'suggestedRetailPrice' },
    { Header: 'Minimum Expiration Date', accessor: 'expectedExpirationDate' },
    { Header: 'Minimum Production Date', accessor: 'expectedProductionDate' },
    { Header: 'Where Would It Go?', accessor: 'unpurchasedOutcome' },
    { Header: "Why I'm a Misfit", accessor: 'sourcingReasons' },
    { Header: 'Country Code of Origin', accessor: 'countryCodeOfOrigin' },
];

const createdPurchaseOrderColumns = [
    { accessor: 'id', Header: 'PO #' },
    { accessor: 'wasVendorEmailed', Header: 'Vendor Emailed', Cell: ({ row }) => VendorEmailedColumn(row) },
    { accessor: 'createdAt', Header: 'Created On' },
    { accessor: 'status', Header: 'Status' },
    { accessor: 'truckID', Header: 'Truck ID' },
    { accessor: 'deliveryType', Header: 'Delivery Type' },
    { accessor: 'pickupDate', Header: 'Pick Up Date' },
    { accessor: 'scheduledDeliveryDate', Header: 'Scheduled Delivery' },
    { accessor: 'vendorName', Header: 'Vendor' },
    { accessor: 'shipperName', Header: 'Shipper' },
    { accessor: 'crossDockName', Header: 'Cross Dock' },
    { accessor: 'crossDockDeliveryDate', Header: 'Cross Dock Delivery Date' },
    { accessor: 'facilityName', Header: 'Facility' },
    { accessor: 'poTotalCost', Header: 'Total Cost' },
    { accessor: 'buyers', Header: 'Buyers' },
    { accessor: 'minTempReq', Header: 'Min Temperature' },
    { accessor: 'maxTempReq', Header: 'Max Temperature' },
    { accessor: 'instructions', Header: 'Special Instructions' },
    { accessor: 'priority', Header: 'Priority', Cell: ({ row: { values } }) => values.priority.toString() },
    { accessor: 'estimatedFreightCost', Header: 'Freight Cost' },
];

const purchaseOrderItemColumns = [
    { accessor: 'rowNumber', Header: 'Row #' },
    { accessor: 'warnings', Header: 'Warnings' },
    { accessor: 'facility', Header: 'Facility' },
    { accessor: 'itemAction', Header: 'Action' },
    { accessor: 'reasonCode', Header: 'Removal Reason' },
    { accessor: 'vendor', Header: 'Vendor Name' },
    { accessor: 'shipper', Header: 'Shipper Name' },
    { accessor: 'deliveryType', Header: 'Delivery Type' },
    { accessor: 'pickupDate', Header: 'Pick Up Date' },
    { accessor: 'initialDeliveryDate', Header: 'Original Requested Delivery Date' },
    { accessor: 'deliveryDate', Header: 'Scheduled Delivery Date' },
    { accessor: 'minTempReq', Header: 'Min Temp' },
    { accessor: 'maxTempReq', Header: 'Max Temp' },
    { accessor: 'buyer1', Header: 'Buyer 1' },
    { accessor: 'buyer2', Header: 'Buyer 2' },
    { accessor: 'buyer3', Header: 'Buyer 3' },
    { accessor: 'buyer4', Header: 'Buyer 4' },
    { accessor: 'instructions', Header: 'Special Instructions' },
    { accessor: 'sku', Header: 'Item Master SKU' },
    { accessor: 'externalSku', Header: 'External SKU' },
    { accessor: 'vendorSku', Header: 'Vendor SKU' },
    { accessor: 'skuName', Header: 'SKU Name' },
    { accessor: 'expectedExpirationDate', Header: 'Expected Expiration' },
    { accessor: 'expectedProductionDate', Header: 'Expected Production' },
    { accessor: 'unitPrice', Header: 'Case Cost' },
    { accessor: 'qty', Header: 'Case QTY' },
    { accessor: 'csPallet', Header: 'CS/Pallet' },
    { accessor: 'week', Header: 'Week' },
    { accessor: 'year', Header: 'Year' },
    { accessor: 'purchaseType', Header: 'Purchase Type' },
    { accessor: 'suggestedRetailPrice', Header: 'SRP' },
    { accessor: 'priority', Header: 'Priority', Cell: ({ row: { values } }) => values.priority.toString() },
    { Header: 'Where Would It Go?', accessor: 'unpurchasedOutcome' },
    { Header: "Why I'm a Misfit", accessor: 'sourcingReasons' },
    { Header: 'Country Code of Origin', accessor: 'countryCodeOfOrigin' },
    { accessor: 'estimatedFreightCost', Header: 'Freight Cost' },
];

const ViewItemsTable = ({ parentRow, uploadResults }) => {
    let [key, setKey] = useState(parentRow.original.groupKey);
    const [rows, setRows] = useState([]);

    let getItemsInPO = async () => {
        if (key == null) {
            return;
        }

        const poItems = uploadResults.filter((item) => {
            if (item.groupKey === key) return item;
        });

        const data = poItems.map((item) => ({
            ...item,
            itemAction: item.rowAction ?? '',
        }));

        setRows(data);
    };

    useEffect(() => {
        getItemsInPO();
    }, []);

    return (
        <div>
            <DataTable columns={purchaseOrderItemColumns} data={rows} />
        </div>
    );
};
const VendorEmailedColumn = (row) => {
    const useStyles = makeStyles((theme) => ({
        warningIcon: {
            color: theme.palette.warning.main,
        },
    }));

    const classes = useStyles();

    if (!!row.original.emailVendorErrors.length) {
        return <WarningIcon className={classes.warningIcon} />;
    }
    if (row.original.wasVendorEmailed) {
        return <CheckIcon style={{ color: 'green' }} />;
    }
    return <div />;
};

const renderVendorEmailErrors = (posWithErrors) => {
    return posWithErrors.map((po) => {
        return (
            <div>
                - PO #{po.id}: {po.errors.join('. ')}
            </div>
        );
    });
};

const BulkCreatePurchaseOrders = () => {
    const [isUploadValid, setIsUploadValid] = useState(undefined);
    const [createdPurchaseOrders, setCreatedPurchaseOrders] = useState([]);
    const [purchaseOrdersWithEmailErrors, setPurchaseOrdersWithEmailErrors] = useState([]);
    const [columns, setColumns] = useState([]);
    const [uploadResults, setUploadResults] = useState([]);

    useEffect(() => {
        if (createdPurchaseOrders.length) {
            setColumns(createdPurchaseOrderColumns);
        } else {
            if (isUploadValid) {
                setColumns(purchaseOrderColumns);
            } else {
                setColumns([...validationColumns, ...lineItemColumns]);
            }
        }
    }, [isUploadValid, createdPurchaseOrders]);

    useEffect(() => {
        setPurchaseOrdersWithEmailErrors(
            createdPurchaseOrders
                .filter((po) => !!po.emailVendorErrors.length)
                .map((po) => ({ id: po.id, errors: po.emailVendorErrors }))
        );
    }, [createdPurchaseOrders]);

    const confirmUpload = async (key) => {
        const res = await api.confirmPurchaseOrdersUpload(key);
        return res;
    };

    const uploadPurchaseOrders = async (formData) => {
        setCreatedPurchaseOrders([]);
        setPurchaseOrdersWithEmailErrors([]);
        const res = await api.uploadPurchaseOrders(formData);
        if (!res.status || res.data.error) {
            setIsUploadValid(false);
            return res;
        } else {
            setIsUploadValid(true);

            const data = res.data.results;

            // Get one row per distinct group key in order to represent unique POs
            const groupKeys = Array.from(new Set(data.map((r) => r.groupKey)));
            const uniquePurchaseOrders = groupKeys.map((key) => data.find((row) => row.groupKey === key));

            const purchaseOrders = uniquePurchaseOrders.map((item) => ({
                ...item,
                warnings: [],
            }));

            groupKeys.forEach((key) => {
                const filteredOrders = data.filter((result) => result.groupKey === key);
                filteredOrders.forEach((order) => {
                    if (order.warnings?.length > 0) {
                        const foundOrder = purchaseOrders.find((item) => item.groupKey === key);
                        foundOrder.warnings.push(order.warnings);
                    }
                });
            });

            let keysAndCostArr = groupKeys.map((item) => ({ key: item, totalCost: 0 }));
            let error = res.data.error;

            res.data.results.map((po) => {
                keysAndCostArr.find((item) => item.key === po.groupKey).totalCost += po.unitPrice * po.qty;
            });

            keysAndCostArr.map((item) => (item.totalCost = Math.round(item.totalCost * 100) / 100));

            keysAndCostArr.forEach((item) => {
                const purchaseOrder = purchaseOrders.find((po) => po.groupKey === item.key);

                if (purchaseOrder && item.totalCost >= PO_WARNING_VALUE) {
                    purchaseOrder.errors.push('PO total cost above 200k');
                } else if (purchaseOrder && item.totalCost >= PO_ALERT_VALUE && item.totalCost < PO_WARNING_VALUE) {
                    purchaseOrder.warnings.push('PO total cost above 50k');
                }

                purchaseOrder.totalCost = item.totalCost;
            });

            setUploadResults(res.data.results);
            return {
                ...res,
                data: {
                    error: error,
                    warning: res.data.warning,
                    key: res.data.key,
                    results: purchaseOrders,
                },
            };
        }
    };

    const downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,Facility*,Vendor*,';
        csvContent += 'Shipper(*),Cross Dock,Cross Dock Delivery Date,Delivery Type*,';
        csvContent += 'Pick Up Date(*),Original Requested Delivery Date*,Scheduled Delivery Date*,';
        csvContent +=
            'Min Temp,Max Temp,Buyer 1 Email*,Buyer 2 Email,Buyer 3 Email,Buyer 4 Email,Special Instructions,Priority,Int SKU*,';
        csvContent += 'Vendor SKU,Case QTY*,Case Cost*,CS/Pallet*,Week*,Year,Purchase Type*,SRP,';
        csvContent += 'Expected Production Date(*),Expected Expiration Date(*),Where Would It Go?,';
        csvContent += "Why I'm a Misfit,Country Code of Origin*,Email Vendor,Freight Cost,PO Number,Action,Reason";
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'purchase_orders_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <p>
                Select a CSV using the form below, then click SUBMIT to review the purchase orders that will be created.
            </p>
            <UploadCSVAndReviewComponent
                title="Upload Purchase Orders"
                fileLabel="Select File"
                columns={columns}
                upload={uploadPurchaseOrders}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
                onSuccess={(data) => {
                    const pos = data.purchaseOrders.map((row) => ({
                        ...row,
                        createdAt: moment(row.createdAt.date).format('MM/DD/YYYY'),
                        pickupDate: !!row.pickupDate ? moment(row.pickupDate.date).format('MM/DD/YYYY') : '',
                        scheduledDeliveryDate: moment(row.scheduledDeliveryDate.date).format('MM/DD/YYYY HH:mm:ss'),
                        crossDockDeliveryDate: !!row.crossDockDeliveryDate
                            ? moment(row.crossDockDeliveryDate.date).format('MM/DD/YYYY')
                            : '',
                        buyers: row.buyers.map((b) => b.name).join(', '),
                    }));
                    setCreatedPurchaseOrders(pos);
                    setIsUploadValid(undefined);
                }}
                hideValidRowsOnError={true}
                expandable={true}
                expansionComponent={<ViewItemsTable uploadResults={uploadResults} />}
            />

            {!!createdPurchaseOrders.length && (
                <div style={{ marginTop: '50px' }}>
                    <Alert severity="success">Purchase orders created successfully</Alert>
                    {!!purchaseOrdersWithEmailErrors.length && (
                        <Alert severity="warning" style={{ textAlign: 'left' }}>
                            The following purchase orders have been created but failed to be emailed to the vendor.
                            Please review the vendor email addresses and resend the purchase order to the vendor once
                            corrected.
                            <br />
                            {renderVendorEmailErrors(purchaseOrdersWithEmailErrors)}
                        </Alert>
                    )}
                    <DataTable columns={createdPurchaseOrderColumns} data={createdPurchaseOrders} />
                </div>
            )}
        </div>
    );
};

export { BulkCreatePurchaseOrders };
