import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { UploadCSVAndReviewComponent } from '../../../shared';
import { goPuffApi } from '../../../api/goPuff';
import { doorDashApi } from '../../../api/doorDash';
import { UserContext } from '../../../contexts/UserContext';

let WholesalePricingUpload = () => {
    const { wholesaleAccount } = useContext(UserContext);
    let [api, setApi] = useState(wholesaleAccount.fbmAccountName === 'doordash' ? doorDashApi : goPuffApi);

    useEffect(() => {
        if (wholesaleAccount.fbmAccountName === 'doordash') {
            setApi(doorDashApi);
        } else {
            setApi(goPuffApi);
        }
    }, [wholesaleAccount]);

    let columns = [
        { Header: 'External SKU', accessor: 'externalSku' },
        { Header: 'Effective Date', accessor: 'effectiveDate' },
        { Header: 'Each Cost', accessor: 'eachCost' },
        { Header: 'Case Cost', accessor: 'caseCost' },
        { Header: 'Currency', accessor: 'currency' },
        { Header: 'OpCo Name', accessor: 'opCoName' },
        { Header: 'Location ID', accessor: 'locationID' },
        { Header: 'Errors', accessor: 'errors' },
        { Header: 'Warnings', accessor: 'warnings' },
    ];

    let confirmUpload = async (key) => {
        let res = await api.confirmPricingUpload(key);
        return res;
    };

    let uploadRows = async (formData) => {
        let res = await api.uploadPricing(formData);
        return res;
    };

    let downloadTemplate = () => {
        let csvContent = 'data:text/csv;charset=utf-8,External SKU,Effective Date,';
        csvContent += 'Each Cost,Case Cost,Currency,OpCo Name,LocationID';
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', wholesaleAccount.fbmAccountName+'_pricing_upload_template.csv');
        document.body.appendChild(link);

        link.click();
    };

    return (
        <div>
            <Link to={'/gopuff/pricing'}>View Pricing</Link>
            <UploadCSVAndReviewComponent
                title={`Upload ${wholesaleAccount.companyName} Pricing`}
                fileLabel="Pricing File"
                columns={columns}
                upload={uploadRows}
                confirm={confirmUpload}
                downloadTemplate={downloadTemplate}
            />
        </div>
    );
};

export { WholesalePricingUpload };
