import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Route, useLocation } from 'react-router-dom';
import { NavDrawer, Login, Catalog } from '../../components';
import { SpecialOrders } from '../../shared';
import {
    SellableItems,
    SellableItemPrices,
    PricingZones,
    SellableItemInventory,
    PackGroups,
    Carousels,
    ViewPricing,
    CarouselUpload,
    BannerCreation,
    SellableItemMisfitsPlusDiscounts,
} from '../../components/production';
import {
    ZipMapUpload,
    ZipMapResults,
    ReassignPurchasedShipments,
    LineHaulManagement,
    LineHaulsComponent,
    InboundTrucks,
    OutboundTrucks,
    ShippingRateUpload,
    ManifestTrucks,
    DbiManagement,
    FBMZipMapUpload,
    DBICrossDocks,
    InboundCrossDocks,
    ViewHolidayOffsets,
} from '../../components/logistics';
import {
    Batchelor,
    BatchelorReprint,
    PrintLabels,
    PurchaseLabels,
    FailedToPurchase,
    StuckShipments,
    DBIDeepDive,
    PurchaseCarrierShipLabels,
    PickupBoxes,
} from '../../components/shipping';
import { ItemInformation, Taxonomy, Bundles, ItemInformationUpload } from '../../components/catalog';
import {
    CubeLevels,
    InternalTransfers,
    InventoryHistory,
    LocationInventory,
    LocationsV2,
    Netsuite,
    PackLines,
    PackLineZones,
    RackLabels,
    Slotting,
    ViewLotV2,
} from '../../components/warehouse';
import {
    Discounts,
    ReferralProgram,
    SpecialOrdersMarketing,
    ProduceList,
    SystemGiftCodes,
    VendorPromotions,
    VipCustomers,
    AcquisitionPDPs,
} from '../../components/marketing';
import { EmployeeBox, JalapenoAccounts, ViewJalapenoProfiles, FoodAsMedicineAccounts } from '../../components/infoTech';
import {
    LittleSpoonSubList,
    FBMViewOrders,
    FBMViewShipments,
    FBMAsn,
    FBMCatalog,
    FBMPackagingAsn,
    FBMInventory,
    ViewFBMShipmentHospital,
    FBMIngestionResults,
    FBMViewReports,
    FBMManageAccounts,
} from '../../components/fbm';
import { Payments, FinanceUploads, DriverTipsReports } from '../../components/finance';
import {
    GoPuffMicroFC,
    GoPuffOrderViewer,
    GoPuffOrderUpload,
    GoPuffPricingUpload,
    ViewGoPuffBatches,
    ViewGoPuffPackout,
    ViewGoPuffPricing,
    ViewGoPuffInvoices,
    GoPuffAdjustmentUpload,
    IssueGoPuffCredits,
    ReviewGoPuffCredits,
} from '../../components/gopuff';
import { 
    ReviewWholesaleCredits, 
    WholesaleOrderUpload, 
    WholesalePricingUpload, 
    WholesaleAdjustmentUpload 
} from '../wholesale/shared/index';
import { EmailCampaigns, NPSResults } from '../../components/growth';
import { MassCredit } from '../../components/compensations';
import { FeatureFlag, CartExperience, DriverTipsSettings } from '../../components/product';
import {
    MarketplaceInventory,
    MarketplaceDrinksInventory,
    OrderDeepDive,
    LiveInventory,
    CCPADashboard,
} from '../../components/system';
import { Vendor } from '../../components/vendor';
import { AssignPackaging, PackagingPurchaseOrder, Packaging, AutoAssignPackaging } from '../../components/packaging';
import { Training } from '../../components/training';
import { HomeScreen } from '../../components/user';
import { PurchaseOrders, CreditsTracking } from '../../components/procurement';
import { QualityControlReceiving, ViewCCOF, ViewQCAuditTasks } from '../../components/quality';
import { ShipmentStatus } from '../../components/opsVisibility';
import { Drinks, ViewFAMOrders, FAMPartners } from '../../components/partnerships';
import { Receiving, PurchaseOrderDocuments, InboundReceiving } from '../../components/receiving';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import './app.css';
import { UserProvider, UserContext } from '../../contexts/UserContext';
import { DataTableProvider } from '../../contexts/TableConfiguration';
import {
    CustManage,
    CarrierLookup,
    CatalogSummary,
    ServiceDelayBanners,
    CustomerManagerBulkTools,
} from '../../components/custmanage';
import { FamApp } from '../../foodAsMedicineComponents';

let colors = {
    mustard: '#F1C34A',
    orange: '#DEA455',
    pepper: '#DE8255',
    tomato: '#DC6B31',
    ivy: '#4C884B',
    aloe: '#B0C472',
    bone: '#F2EBD1',
    white: '#FFFFFF',
    lightGray: '#F6F6F6',
    medGray: '#C3C3C3',
    darkGray: '#969696',
    black: '#2d2d2d',
    texas: '#94122B',
    chiyellow: '#D7AC2B',
    pink: '#B32274',
    purple: '#89099B',
    teal: '#63ACDE',
};

let theme = createMuiTheme({
    palette: {
        secondary: {
            main: colors.tomato,
            dark: colors.pepper,
        },
        background: {
            default: colors.lightGray,
        },
        medGray: {
            main: colors.medGray,
        },
        darkGray: {
            main: colors.darkGray,
        },
        mustard: {
            main: colors.mustard,
        },
        bone: {
            main: colors.bone,
        },
        orange: {
            main: colors.orange,
        },
        pepper: {
            main: colors.pepper,
        },
        tomato: {
            main: colors.tomato,
        },
        aloe: {
            main: colors.aloe,
        },
        ivy: {
            main: colors.ivy,
        },
        texas: {
            main: colors.texas,
        },
        black: {
            main: colors.black,
        },
        chiyellow: {
            main: colors.chiyellow,
        },
        pink: {
            main: colors.pink,
        },
        purple: {
            main: colors.purple,
        },
        teal: {
            main: colors.teal,
        },
    },
});

function App() {
    return (
        <ThemeProvider theme={theme}>
            <UserProvider>
                <DataTableProvider>
                    <BrowserRouter basename={'/lettuce'}>
                        <AppSelector />
                    </BrowserRouter>
                </DataTableProvider>
            </UserProvider>
        </ThemeProvider>
    );
}

function MMApp() {
    return (
        <div className="App">
            {/* <DataTableProvider> */}
            <NavDrawer />
            <div className="content">
                <Route path="/" exact component={HomeScreen} />

                <Route path="/login">
                    <Login />
                </Route>

                <Route path="/logistics/inbound-trucks" component={InboundTrucks} />
                <Route path="/logistics/outbound-trucks" component={OutboundTrucks} />
                <Route path="/logistics/move-purchased" component={ReassignPurchasedShipments} />
                <Route path="/logistics/line-haul-management" component={LineHaulManagement} />
                <Route path="/logistics/zip-map" component={ZipMapUpload} />
                <Route path="/logistics/zip-map-results" component={ZipMapResults} />
                <Route path="/logistics/shipping-rates" component={ShippingRateUpload} />
                <Route path="/logistics/manifest-outbound-trucks" component={ManifestTrucks} />
                <Route path="/logistics/carrier-ship-labels" component={PurchaseCarrierShipLabels} />
                <Route path="/logistics/dbi-management" component={DbiManagement} />
                <Route path="/logistics/fbm-zip-map" component={FBMZipMapUpload} />
                <Route path="/logistics/dbi-cross-docks" component={DBICrossDocks} />
                <Route path="/logistics/inbound-cross-docks" component={InboundCrossDocks} />
                <Route path="/logistics/holiday-offsets" component={ViewHolidayOffsets} />

                <Route exact path="/custmanage" component={CustManage} />
                <Route path="/custmanage/carrier-lookup" component={CarrierLookup} />
                <Route path="/custmanage/catalog" component={CatalogSummary} />
                <Route path="/custmanage/delay-banners" component={ServiceDelayBanners} />
                <Route path="/custmanage/cm-bulk-tools" component={CustomerManagerBulkTools} />

                <Route path="/training" component={Training} />

                <Route path="/fbm/items" component={FBMCatalog} />
                <Route path="/fbm/advance-shipping-notification" component={FBMAsn} />
                <Route path="/fbm/packaging" component={FBMPackagingAsn} />
                <Route path="/fbm/orders" component={FBMViewOrders} />
                <Route path="/fbm/shipments" component={FBMViewShipments} />
                <Route path="/fbm/subs" component={LittleSpoonSubList} />
                <Route path="/fbm/inventory" component={FBMInventory} />
                <Route path="/fbm/hospital" component={ViewFBMShipmentHospital} />
                <Route path="/fbm/ingestion-results" component={FBMIngestionResults} />
                <Route path="/fbm/reports" component={FBMViewReports} />
                <Route path="/fbm/accounts" component={FBMManageAccounts} />

                <Route path="/shipping/batchelor" component={Batchelor} />
                <Route path="/shipping/dbi" component={DBIDeepDive} />
                <Route path="/shipping/reprint" component={BatchelorReprint} />
                <Route path="/shipping/vip" component={PrintLabels} />
                <Route path="/shipping/failed-to-purchase" component={FailedToPurchase} />
                <Route path="/shipping/purchase-label" component={PurchaseLabels} />
                <Route path="/shipping/stuck-shipments" component={StuckShipments} />
                <Route path="/shipping/pickup-boxes" component={PickupBoxes} />
                <Route path="/shipping/special-orders">
                    <SpecialOrders editable={false} />
                </Route>

                <Route path="/warehouse/lots" component={ViewLotV2} />
                <Route path="/warehouse/locations" component={LocationsV2} />
                <Route path="/warehouse/netsuite" component={Netsuite} />
                <Route path="/warehouse/pack-lines" component={PackLines} />
                <Route path="/warehouse/pack-line-zones" component={PackLineZones} />
                <Route path="/warehouse/rack-labels" component={RackLabels} />
                <Route path="/warehouse/cube-levels" component={CubeLevels} />
                <Route path="/warehouse/inventory" component={LocationInventory} />
                <Route path="/warehouse/internal-transfers" component={InternalTransfers} />
                <Route path="/warehouse/slotting" component={Slotting} />
                <Route path="/ops-visibility/inventory-history" component={InventoryHistory} />

                <Route path="/receiving/receiving" component={Receiving} />
                <Route path="/receiving/documents" component={PurchaseOrderDocuments} />
                <Route path="/receiving/inbound-receiving" component={InboundReceiving} />

                <Route path="/catalog/item-information" component={ItemInformation} />
                <Route path="/catalog/bundles" component={Bundles} />
                <Route path="/catalog/item-information-upload" component={ItemInformationUpload} />

                <Route path="/marketing/referralProgram" component={ReferralProgram} />
                <Route path="/marketing/discounts" component={Discounts} />
                <Route path="/marketing/specialOrders" component={SpecialOrdersMarketing} />
                <Route path="/marketing/produceList" component={ProduceList} />
                <Route path="/marketing/giftCodes" component={SystemGiftCodes} />
                <Route path="/marketing/vip-customers" component={VipCustomers} />
                <Route path="/marketing/acquisition-pdps" component={AcquisitionPDPs} />

                <Route path="/procurement/viewPurchaseOrder" component={PurchaseOrders} />
                <Route path="/procurement/taxonomy" component={Taxonomy} />
                <Route path="/procurement/items" component={Catalog} />
                <Route path="/procurement/vendor" component={Vendor} />
                <Route path="/procurement/credits-tracking" component={CreditsTracking} />

                <Route path="/partnerships/drinks" component={Drinks} />
                <Route path="/partnerships/order-import" component={ViewFAMOrders} />
                <Route path="/partnerships/order-sources" component={FAMPartners} />

                <Route path="/production/sellable-items" component={SellableItems} />
                <Route path="/production/sellable-item-prices" component={SellableItemPrices} />
                <Route path="/production/pricing-zones" component={PricingZones} />
                <Route path="/production/sellable-item-inventory" component={SellableItemInventory} />
                <Route path="/production/misfits-plus-discounts" component={SellableItemMisfitsPlusDiscounts} />
                <Route path="/production/carousels" component={Carousels} />
                <Route path="/production/carousel-upload" component={CarouselUpload} />
                <Route path="/production/pack-groups" component={PackGroups} />
                <Route path="/production/pricing" component={ViewPricing} />
                <Route path="/production/banner-creation" component={BannerCreation} />

                <Route path="/quality/receiving" component={QualityControlReceiving} />
                <Route path="/quality/ccof" component={ViewCCOF} />
                <Route path="/quality/view-qc-audit-tasks" component={ViewQCAuditTasks} />

                <Route path="/compensations/mass-credit" component={MassCredit} />

                <Route path="/packaging/assign" component={AssignPackaging} />
                <Route path="/packaging/auto-assign" component={AutoAssignPackaging} />
                <Route path="/packaging/packaging" component={Packaging} />
                <Route path="/packaging/purchase" component={PackagingPurchaseOrder} />

                <Route path="/product/feature-flag" component={FeatureFlag} />
                <Route path="/product/cart-experience" component={CartExperience} />
                <Route path="/product/driver-tips-settings" component={DriverTipsSettings} />

                <Route path="/opsVisibility/shipment-status" component={ShipmentStatus} />

                <Route path="/shopperMarketing/vendor-promotions" component={VendorPromotions} />

                <Route path="/system/ccpa-dashboard" component={CCPADashboard} />
                <Route path="/system/marketplace-inventory" component={MarketplaceInventory} />
                <Route path="/system/marketplace-drinks-inventory" component={MarketplaceDrinksInventory} />
                <Route path="/system/order-deep-dive" component={OrderDeepDive} />
                <Route path="/system/live-inventory" component={LiveInventory} />

                <Route path="/infoTech/jalapenoAccounts" component={JalapenoAccounts} />
                <Route path="/infoTech/jalapenoProfiles" component={ViewJalapenoProfiles} />
                <Route path="/infoTech/employeeBox" component={EmployeeBox} />
                <Route path="/infoTech/food-as-medicine" component={FoodAsMedicineAccounts} />

                <Route path="/growth/campaigns" component={EmailCampaigns} />
                <Route path="/growth/nps" component={NPSResults} />

                <Route path="/finance/driver-tip-finance" component={DriverTipsReports} />
                <Route path="/finance/payments" component={Payments} />
                <Route path="/finance/uploads" component={FinanceUploads} />

                <Route path="/gopuff/batches" component={ViewGoPuffBatches} />
                <Route path="/gopuff/micro-fc" component={GoPuffMicroFC} />
                <Route path="/gopuff/orders" component={GoPuffOrderViewer} />
                <Route path="/gopuff/order-upload" component={WholesaleOrderUpload} />
                <Route path="/gopuff/packout" component={ViewGoPuffPackout} />
                <Route path="/gopuff/invoices" component={ViewGoPuffInvoices} />
                <Route path="/gopuff/pricing" component={ViewGoPuffPricing} />
                <Route path="/gopuff/pricing-upload" component={WholesalePricingUpload} />
                <Route path="/gopuff/adjustments-upload" component={WholesaleAdjustmentUpload} />
                <Route path="/gopuff/issue-po-credits" component={IssueGoPuffCredits} />
                <Route path="/gopuff/review-po-credits" render={(props) => <ReviewWholesaleCredits {...props} />} />
            </div>
            {/* </DataTableProvider> */}
        </div>
    );
}

function AppSelector() {
    const location = useLocation();
    const [showFamApp, setShowFamApp] = useState(location.pathname.includes('/partners/'));
    const { user } = useContext(UserContext);

    useEffect(() => {
        setShowFamApp(location.pathname.includes('/partners/'));
    }, [location.pathname]);

    if (user.famPartner === true || showFamApp) {
        return <FamApp />;
    } else if (user.famPartner === false) {
        return <MMApp />;
    } else {
        return null;
    }
}

export default App;
