import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, Paper, Typography, Box } from '@material-ui/core';
import { ViewGoPuffOrders } from '../components/viewGoPuffOrders.js';
import { ViewGoPuffPOCredits } from '../components/viewGoPuffPOCredits.js';
import { TabPanel, WholesaleAccountPicker } from '../../../shared';
import { UserContext } from '../../../contexts/UserContext';
import { ViewWholesaleOrders, ViewWholesalePOCredits } from '../../wholesale/shared/index.js';

const GoPuffOrderViewer = ({ baseUrl }) => {
    let [selectedTab, setSelectedTab] = useState('0');
    let handleChange = (event, value) => {
        setSelectedTab(value);
    };
    const { wholesaleAccount } = useContext(UserContext);

    return (
        <Paper>
            <WholesaleAccountPicker />
            {!!wholesaleAccount.id && (
                <>
                    <Tabs onChange={handleChange} value={selectedTab} indicatorColor="primary" textColor="primary" centered>
                        <Tab label="View Orders" value="0" />
                        <Tab label="View Credits" value="1" />
                    </Tabs>
                    <TabPanel selectedTab={selectedTab} index={'0'}>
                        <ViewWholesaleOrders wholesaleAccount={wholesaleAccount} />
                    </TabPanel>
                    <TabPanel selectedTab={selectedTab} index={'1'}>
                        <ViewWholesalePOCredits wholesaleAccount={wholesaleAccount} />
                    </TabPanel>
                </>
            )}
        </Paper>
    );
};

export { GoPuffOrderViewer };
