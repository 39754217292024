import React from 'react';
import { DataTable } from '../../../shared';

const RecurringItems = ({ recurringItems, classes }) => {
    const columns = [
        { Header: 'Item', accessor: 'item' },
        { Header: 'Last Sold Price', accessor: 'price' },
    ];

    const data = recurringItems.map((item) => {
        let nameAndBrand = !item.brand ? '' : `${item.brand} `;
        nameAndBrand += item.name;
        return {
            id: item.externalSku,
            item: nameAndBrand,
            price: item.price,
        };
    });

    return (
        <div className={classes.card}>
            <span>
                <strong>{recurringItems.length} Recurring Items</strong>
            </span>
            <div className="data-table-hide-toolbar">
                <DataTable columns={columns} data={data} />
            </div>
        </div>
    );
};

export { RecurringItems };
