import { mmFetchWithResponse } from './mmFetchWithResponse';

let customerApi = {
    addCredit: async (subID, sendParams) => {
        let url = `api/credits/v1/${subID}/create`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    applyCredit: async (subID, sendParams) => {
        let url = `api/discounts/v1/${subID}/auto`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    applyStoreCredit: async (chargeID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/credit`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    cancelOrder: async (chargeID, sendParams) => {
        let url = `api/charge/v2/${chargeID}/refund/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    cancelOrderLineItem: async (chargeID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/refund/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    cancelAndRefundByShipment: async (chargeID, shipmentID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/refund/cancel/shipment/${shipmentID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    cancelShipment: async (chargeID, shipmentID) => {
        let url = `api/charge/v3/${chargeID}/cancel/${shipmentID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    cancelSubscription: async (subID) => {
        let url = `api/subscription/v1/${subID}/cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    /**
     * Update delivery day and/or order interval for a user.
     * @param {string} subscriptionID - Subscription ID of the user.
     * @param {Object} sendParams - The params to send to the server.
     * @param {string} sendParams.prefDeliveryDay - New preferred delivery day.
     * @param {string} sendParams.orderInterval - [Optional] New preferred order unit interval (1 = weekly, 3 = biweekly even weeks, 4 = biweekly odd weeks).
     */
    changeDeliveryInformation: async (subID, sendParams) => {
        let url = `api/subscription/v1/${subID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    convertToAutoship: async (subID) => {
        let url = `api/subscription/v1/${subID}/autoship`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    convertToMembership: async (subID, reason, secondaryReason) => {
        let url = `api/subscription/v1/${subID}/membership`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ reason, secondaryReason }),
        });
        return response;
    },
    getAccountInfo: async (custID) => {
        let url = `api/customer/v2/account/${custID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getChangelog: async (subID) => {
        let url = `api/subscription/v1/${subID}/changelog`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getBulkCustomerUploads: async () => {
        let url = `api/customer/v1/bulkCustomerUploads`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDiscountDetails: async (subID) => {
        let url = `api/discounts/v1/${subID}/list`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getEmailNotificationPreferences: async (custID) => {
        let url = `api/customer/v1/${custID}/notifications`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getCustomerCommunications: async (custID) => {
        let url = `api/customer/v1/${custID}/communications`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrderDetails: async (orderNum) => {
        let url = `api/order/v4/${orderNum}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getOrders: async (custID) => {
        let url = `api/customer/v3/${custID}/orders?page=0&pageSize=40`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getRefundableItems: async (chargeID) => {
        let url = `api/charge/v3/${chargeID}/refundableItems`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getSubscription: async (custID) => {
        let url = `api/subscription/v2/${custID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDrinksOrders: async (custID) => {
        let url = `api/partnerships/v1/drinksOrders/${custID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDrinksOrderDetails: async (drinksOrderID) => {
        let url = `api/partnerships/v1/drinksOrders/details/${drinksOrderID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getDrinksRefundableItems: async (drinksOrderID) => {
        let url = `api/partnerships/v1/drinksRefund/${drinksOrderID}/refundableDrinksItems`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getV2Plans: async () => {
        let url = `api/marketplace/v1/autoship/plans`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getPersonalizationInfo: async (customerID) => {
        let url = `api/customer/v1/personalization-info/${customerID}`;
        return mmFetchWithResponse(url);
    },
    overrideSeedingRange: async (customerID, min, max) => {
        let url = `api/customer/v1/personalization-info/default/${customerID}`;
        return mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ min, max }),
        });
    },
    inspectCharge: async (chargeID) => {
        let url = `api/charge/v1/${chargeID}/inspect`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    massCancelOrders: async (orderFile) => {
        let formData = new FormData();
        formData.append('orderIDFile', orderFile);
        let url = `api/customer/v1/mass-cancel`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    massFullRefund: async (refundFile, reason) => {
        let formData = new FormData();
        formData.append('refundIDFile', refundFile);
        formData.append('reason', reason);
        let url = `api/charge/v3/massRefund/full`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    pauseSubscription: async (subID, numMonths) => {
        let url = `api/subscription/v1/${subID}/pause`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ numMonths }),
        });
        return response;
    },
    reactivateSubscription: async (subID) => {
        let url = `api/subscription/v1/${subID}/reactivate`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    refundItems: async (refundChargeID, sendParams) => {
        let url = `api/charge/v2/${refundChargeID}/refund/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundLineItems: async (refundChargeID, sendParams) => {
        let url = `api/charge/v3/${refundChargeID}/refund/items`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundOrder: async (refundChargeID, sendParams) => {
        let url = `api/charge/v2/${refundChargeID}/refund`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundOrderLineItem: async (chargeID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/refund/full`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundByShipment: async (chargeID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/refund/shipment`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundShipping: async (chargeID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/refund/shipping`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundColdKeeper: async (chargeID, sendParams) => {
        let url = `api/charge/v3/${chargeID}/refund/coldKeeperFee`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    refundTip: async (refundChargeID) => {
        let url = `api/charge/v3/${refundChargeID}/refund/tip`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    fullRefundDrinksOrder: async (drinksOrderID, sendParams) => {
        let url = `api/partnerships/v1/drinksRefund/${drinksOrderID}/full`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    partialRefundDrinksOrder: async (drinksOrderID, sendParams) => {
        let url = `api/partnerships/v1/drinksRefund/${drinksOrderID}/partial`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    removeBilling: async (subID) => {
        let url = `api/subscription/v4/${subID}/billing/remove`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    removeDiscount: async (discountID) => {
        let url = `api/discounts/v1/${discountID}/remove`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    resetPassword: async (custID) => {
        let url = `api/customer/v2/account/${custID}/resetPassword`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    resumeSubscription: async (subID) => {
        let url = `api/subscription/v1/${subID}/resume`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    searchCustomers: async (searchTerm, searchBy) => {
        let url =
            searchBy === 'orderNumber'
                ? `api/order/v1/${searchTerm}/customer`
                : `api/customer/v1/search?${searchBy}=${searchTerm.trim()}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    searchCarriers: async (zip) => {
        let url = `api/customer/v1/carriers/${zip}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    skipCharge: async (chargeID) => {
        let url = `api/charge/v2/${chargeID}/skip`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ reason: 'Customer Care Skip' }),
        });
        return response;
    },
    unskipCharge: async (chargeID) => {
        let url = `api/charge/v2/${chargeID}/unskip`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
        });
        return response;
    },
    updateEmail: async (subID, email) => {
        let url = `api/subscription/v2/${subID}/email`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify({ email }),
        });
        return response;
    },
    updateEmailNotificationPreferences: async (custID, sendParams) => {
        let url = `api/customer/v1/${custID}/notifications`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateShippingAddress: async (subID, address) => {
        let url = `api/subscription/v1/${subID}/address`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(address),
        });
        return response;
    },
    updateSMSNotificationPreferences: async (custID, sendParams) => {
        let url = `api/customer/v1/${custID}/sms`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updateSubscription: async (subID, sendParams) => {
        /**
            valid send param keys: productID, orderInterval, numItems, prefDeliveryDay
         */
        let url = `api/subscription/v1/${subID}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    updatePreferredTip: async (subID, newTip) => {
        let sendParams = { tipAmount: newTip };
        let url = `api/subscription/v1/${subID}/tips/preferences`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    getCreditsBalance: async (subID) => {
        let url = `api/credits/v1/${subID}/balance`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    removeCredit: async (creditID) => {
        let url = `api/credits/v1/${creditID}/remove`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    getGiftCodes: async (subID) => {
        let url = `api/giftCodes/v1/${subID}/list`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    getMembershipSummary: async (custID) => {
        let url = `api/plus/v1/${custID}/summary`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateMembershipAutoRenew: async (custID, isAutoRenew) => {
        let formData = new FormData();
        formData.append('autoRenewalFlag', isAutoRenew);

        let url = `api/plus/v1/${custID}/autorenew`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    cancelMembership: async (custID) => {
        let formData = new FormData();
        formData.append('reason', 'CS Cancelled Membership');

        let url = `api/plus/v1/${custID}/cancelMembership`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    cancelTrial: async (custID) => {
        let formData = new FormData();
        formData.append('reason', 'CS Cancelled Free Trial');

        let url = `api/plus/v1/${custID}/cancelTrial`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    refundMembership: async (custID, plusMembershipChargeID, refundType, refundReason, refundReasonSecondary) => {
        let formData = new FormData();
        formData.append('plusMembershipChargeID', plusMembershipChargeID);
        formData.append('refundReason', refundReason);
        formData.append('refundReasonSecondary', refundReasonSecondary);
        formData.append('refundType', refundType);

        let url = `api/plus/v1/${custID}/refund`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    grantFreeTrial: async (custID, endDate) => {
        let formData = new FormData();
        formData.append('endDate', endDate);

        let url = `api/plus/v1/${custID}/grantFreeTrial`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getAllPromoCodes: async (subID) => {
        let url = `api/customer/v1/promoCodeList/${subID}`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    resendGiftCode: async (code) => {
        let url = `api/giftCodes/v1/resend?code=${code}`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
        });
        return response;
    },
    getDelayBanners: async () => {
        let url = `api/customer/v1/serviceDelayBanners`;
        let response = await mmFetchWithResponse(url);
        return response;
    },
    updateDelayBanners: async (sendParams) => {
        let id = sendParams.id;
        let url = `api/customer/v1/serviceDelayBanner/${id}`;
        let response = await mmFetchWithResponse(url, {
            method: 'PUT',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createDelayBanner: async (sendParams) => {
        let formData = new FormData();
        Object.keys(sendParams).forEach((key) => {
            formData.append(key, sendParams[key]);
        });

        let url = `api/customer/v1/serviceDelayBanner`;
        let response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: formData,
            processData: false,
            headers: {},
        });
        return response;
    },
    getCognitoStatus: async (custID) => {
        const url = `api/customer/v1/${custID}/cognito/exists`;
        const response = await mmFetchWithResponse(url);
        return response;
    },

    createCognitoAccount: async (custID, sendParams) => {
        const url = `api/customer/v1/${custID}/cognito/create`;
        const response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
    createCognitoTempPassword: async (custID, sendParams = {}) => {
        const url = `api/customer/v1/${custID}/cognito/settemp`;
        const response = await mmFetchWithResponse(url, {
            method: 'POST',
            body: JSON.stringify(sendParams),
        });
        return response;
    },
};

export { customerApi };
